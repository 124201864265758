.ContactForm {
    width: 100%;
    padding: 2rem;
    background: white;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -8px rgba(0,0,0,0.75);
    margin: 1rem 0;
    border-radius: 20px;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.form-group {
    margin-bottom: 1rem;
}
#contact-form label {
    display: block;
    margin-bottom: .1rem;
    float: left;
}
.form-control {
    display: block;
    width: 100% !important;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
textarea.form-control {
    height: auto;
}
#contact-form textarea {
    overflow: auto;
    resize: vertical;
}
.captcha-div div{
    width: 100% !important;
}
.captcha-div iframe{
    width: 100% !important;
}
#btnContactoLanding{
    height: 48px;
}