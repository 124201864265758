:root {
    --primary: #fff;
    --blue: #215fe6;
    --light-blue: #5083f1;
    --blue-dark: #0f2147;
    --sky: #0aa3db;
    --gray: #464646;
    --dark: #242424;
    --orange: #df8105;
    --orange-dark: #df7e00;
    --blue-test: #d9e3f7;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--small {
    padding: 6px 16px;
    font-size: 14px;
  }

  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  
  .btn--wide {
    padding: 12px 64px;
    font-size: 20px;
  }
  
  .btn--small:hover,
  .btn--large:hover,
  .btn--medium:hover,
  .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
  }
  
  .btn--wide:hover {
    color: #fff;
    background-color: var(--orange-dark);
    transition: all 0.2s ease-out;
  }
  
  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }
  
  .blue {
    background-color: var(--blue);
    color: #fff;
    border: none;
  }

  .blue2 {
    background-color: var(--blue);
    color: #fff;
    border: none;
  }

  .green {
    background-color: rgb(6, 197, 64);
    color: #fff;
    border: none;
  }

  #btn_si_acepto{
    margin-left: 85%;
  }

  
  .sky {
    background-color: var(--sky);
    color: white;
    border: none;
  }
  
  .primary {
    background-color: var(--dark);
    color: #fff;
    border: none;
  }
  
  .primary:hover {
    background-color: #fff;
    color: var(--dark);
    border: none;
  }
  
  .orange {
    background: var(--orange);
    color: #fff;
    border: none;
  }
  
  .orange:hover {
    background-color: var(--gray);
    color: white;
  }
  .sky:hover {
    background-color:var(--blue-dark);
    color: white;
    border: none;
  }
  .blue2:hover {
    background-color: var(--blue-dark);
    color: #fff;
    border: none;
  }

/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
  .btn--wide {
    padding: 1rem;
  }
}