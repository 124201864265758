.slider-section{
    padding: 5rem 6rem;
    background: #eeeeee;
}
.slick-prev, .slick-next{
    filter: invert(.5);
}
.slick-prev:before, .slick-next:before {
  font-size: 25px;
}
.slider_title {
    font-size: 5vw;
    color: tomato;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2vw;
  }

  .carousel .slick-dots {
    bottom: -2.5vw;
  }
  
  .carousel .slick-dots li button {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .carousel .slick-dots li button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    content: "";
    text-align: center;
    opacity: .5;
    color: #000;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  
  .carousel .slick-dots li.slick-active button, .carousel .slick-dots li:hover button {
    border-color: tomato;
  }
  
  .carousel .slick-dots li.slick-active button:before, .carousel .slick-dots li:hover button:before {
    background-color: tomato;
    opacity: 1;
  }
  
  .carousel .slick-prev, .carousel .slick-next {
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  
  .carousel .slick-prev:hover, .carousel .slick-prev:focus, .carousel .slick-next:hover, .carousel .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
  }
  
  .carousel .slick-prev:before, .carousel .slick-next:before {
    color: #000;
    font-family: "Raleway", sans-serif;
  }
  
  .carousel .slick-next {
    right: -55px;
  }
  
  .carousel .slick-prev {
    left: -55px;
  }
  .slider-section h3 {
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: -1.5rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
}
@media screen and (max-width: 620px) {
  .slider-section {
    padding: 5rem 2rem;
    background: #eeeeee;
  }
}
/**CELULAR****************************************/
  @media screen and (max-width: 420px) {
    .slider-section {
      padding: 5rem 1rem;
      background: #eeeeee;
    }
  }
  