/*CAROUSEL ANUNCIOS**********************************************************/
div#div_avisos {
    position: relative;
    max-width: 1300px;
}
#album-rotator {
    width: calc(100% + 40px);
    white-space: nowrap;
    overflow: overlay;
    position: relative;
    word-break: keep-all;
    padding: 0.6rem 0 1rem 0;
    margin-left: -20px;
    padding-left: 1rem;
}
#div_avisos .arrow-right, #div_avisos .arrow-left{
    position: absolute;
    font-size: 2rem;
    z-index: 100;
    top: calc(40%);
    background: #0e60b3;
    width: 2.1rem;
    height: 2.7rem;
    border-radius: 18px;
    color: white;
    font-weight: 900;
    font-family: 'Fredoka One', cursive;
    line-height: 1.2;
    transition: all .15s ease;
}
#div_avisos .arrow-right{
    right: -36px;
}
#div_avisos .arrow-left{
    left: -36px;    
}
#div_avisos .arrow-right:hover, #div_avisos .arrow-left:hover{
    transform: scale(1.03);
    cursor: pointer;
}
#album-rotator-holder {
    position: relative;
    user-select: none;
    will-change: transform;
    /* transition: all 0.01s ease-out; */
    /* transition: all 0.01s linear; */
}
/* Works on Chrome, Edge, and Safari */
#album-rotator::-webkit-scrollbar {
    width: auto;
    height: .25rem;
    margin: .5rem;
    /* display: none; */
}
#album-rotator::-webkit-scrollbar-track {
    background: transparent;
    /* display: none; */
    margin: 0 1rem;
}
#album-rotator::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0);
    height: .25rem;
    border-radius: 10px;
    /* display: none; */
}
#div_avisos h5 {
    color: #2d6192;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}
.arrow-left svg, .arrow-right svg {
    height: 100%;
}
/*TABLET ****/
@media screen and (max-width: 890px) {
    #album-rotator {
        width: 90%;
    }
    #div_avisos .arrow-right {
        right: -10px;
    }
    #div_avisos .arrow-left{
        left: -10px;
    }
    #div_avisos .arrow-right, #div_avisos .arrow-left {
        font-size: 1.8rem;
        width: 1.85rem;
        height: 2.4rem;
        line-height: 1.1;
        top: calc(45%);
    }
}
/*CELULAR ****/
@media screen and (max-width: 490px) {
    #album-rotator {
        width: 90%;
    }
    #div_avisos .arrow-right {
        right: 0px;
    }
    #div_avisos .arrow-left{
        left: 0px;
    }
    #div_avisos .arrow-right, #div_avisos .arrow-left {
        font-size: 1.5rem;
        width: 1.6rem;
        height: 2rem;
        line-height: 1.2;
        top: calc(45%);
    }
}