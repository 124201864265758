.Contenido{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Contenido h1{
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
}
.Contenido p {
    font-size: 18px;
    margin-bottom: 20px;
}

.Overlay{
    width: 100vw;
    height: 100vh;
    position: fixed; 
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContenedorModal{
    /* margin-top: 27%; */
    width: 500px;
    min-height: 10px;
    height: calc(100% - 25%);
    background: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    padding: 20px;
    overflow: overlay;
}
@media screen and (max-width: 800px) {
    .EncabezadoModal{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #E8E8E8;
        /* position: fixed; */
        background: white;
        z-index: 1000;
        width: 100%;
        margin-left: -20px;
        margin-top: -20px;
        padding: 1.3rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: calc(2% - constant(safe area inset bottom) - constant(safe area inset top));
        height: calc(2% - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        margin-top:  calc(6px + constant(safe area inset top)); /** IOS compatible < 11.2*/
        margin-top: calc(6px + env(safe-area-inset-top));
    }

    #correo_informativo{
        font-size: 60%;
    }
}

#modal_fiscal{
    width: 100%;
    overflow-y: auto;
  }

.BotonCerrar{
    position: absolute;
    top: 16px;
    right: 3px;
    z-index: 200000;
    width: 30px;
    heigth: 30px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #1766DC;
}
.BotonCerrar:hover{
    background: #f2f2f2;

}
.BotonCerrar svg {
    width: 70%;
    height: 70%;
}

.Modal{
    margin-top: 10px;
    position: relative;
    width: 100%;
    background: none;
    outline: none;
    border: none;
}
/* Works on Firefox */
.ContenedorModal {
    scrollbar-width: thin;
    scrollbar-color: blue white;
  }
  
  /* Works on Chrome, Edge, and Safari */
  .ContenedorModal::-webkit-scrollbar {
    width: 12px;
    margin-top: 68px;
  }
  
  .ContenedorModal::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  
  .ContenedorModal::-webkit-scrollbar-thumb {
    background-color: rgb(106, 155, 216);
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
  }