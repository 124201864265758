.divTexto {
    margin-top: 50px;
}

.parrafo {
    color: #0070C0; 
    text-align: center;
    font-size: 50px;
}

.btnIngresar {
    margin-top: 40px;
    background-color: #008CBA;
    border: none;
    border-radius: 1rem;
    color: #ffffff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
}

.btnIngresar:hover {
    margin-top: 40px;
    background-color: #249fc9;
    border: none;
    border-radius: 1rem;
    color: #ffffff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
}

.aviso {
    font-size: 25px;
}

span {
    font-weight: bold;
}

@media (max-width: 768px) {
    .divTexto {
        margin-top: 50px;
    }

    .parrafo {
        color: #0070C0; 
        text-align: center;
        font-size: 25px;
    }
}

@media (max-width: 910px) {
    .divTexto {
        margin-top: 50px;
    }

    .parrafo {
        color: #0070C0; 
        text-align: center;
        font-size: 30px;
    }
}