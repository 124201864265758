.seguro-container {
    display: flex;
    justify-content: center;
}
.seg-part {
    display: flex;
}
.seg-part:first-of-type{
    /* width: 75%; */
    place-content: baseline;
}
.seg-part:last-of-type{
    width: 25%;
}
.seg-card {
    border-radius: 10px;
    padding: .5rem;
    margin: .5rem;
}
.seg-status{
    width: 20%;
    min-width: 130px;
    max-width: 170px;
    display: flex;
    background: #3266aa;
    background: linear-gradient(0deg, #3266aa 0%, #3473c5 74%);
}
.seg-shield {
    width: 50%;
    align-self: center;
    align-items: center;
}
.seg-status-content {
    width: 50%;
    align-self: center;
    align-items: center;
}
.seg-green{
    background: rgb(95, 179, 62);
    background: linear-gradient(0deg, rgb(95, 179, 62) 0%, rgb(102, 207, 60) 74%);
}
.seg-red{
    background: rgb(179, 62, 78);
    background: linear-gradient(0deg, rgb(179, 62, 78) 0%, rgb(207, 77, 60)74%);
}
.seg-yellow{
    background: rgb(214, 159, 7);
    background: linear-gradient(0deg, rgb(214, 142, 7) 0%, rgb(218, 167, 57)74%);
}
.seg-data{
    width: 80%;
    min-width: 488px;
    max-width: 550px;
    background: #3266aa;
    background: linear-gradient(0deg, #3266aa 0%, #3473c5 74%);
    display: flex;
    padding-left: 1rem;
}
.seg-data-content {
    display: flex;
    width: 75%;
    justify-content: center;
    align-items: center;
    align-self: center;
}
svg.shield-icon {
    font-size: 4rem;
    color: white;
}
.seg-status-content p {
    font-size: .75rem;
    font-weight: 600;
}
p.seg-dias {
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
}
.seguro-company {
    width: 25%;
    align-self: center;
}
.seguro-company h1 {
    color: white;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
    font-size: 1.5rem;
    line-height: 1;
}
.seguro-company .form-group p, .seg-data-content .form-group p {
    color: #5cd3ff;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 60%);
}
.form-group {
    margin-bottom: 0;
}
.seguro-company .form-group span {
    text-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
}
.seg-data-content .form-group{
    width: 50%;
}
.seg-data-content .form-group span{
    width: 50%;
    color: white;
}
svg.seg-tel-icon {
    font-size: 2rem;
    color: white;
    height: 95%;
    transition: all .15s ease;
}
svg.seg-tel-icon-off {
    font-size: 2rem;
    height: 95%;
    transition: all .15s ease;
    color: rgb(221, 221, 221);
}
.seg-btn:hover > svg.seg-tel-icon-off{
    cursor:auto;
    color: rgb(221, 221, 221);
    text-shadow: none;
    transform: none;
}
.seg-card.seg-contact {
    width: 50%;
    min-width: 150px;
    max-width: 243px;
    border: 3px solid #4891f0;
    display: flex;
    padding: 0;
}
.seg-tel {
    width: 70%;
    margin: 0.5rem;
    align-self: center;
}
.seg-tel p{
    font-size: .9rem;
    font-weight: 600;
    color: #3266aa;
}
.seg-tel span{
    font-size: 1rem;
    color: rgb(63, 63, 63);
    font-weight: 700;
    line-height: 1;
}
.seg-btn {
    width: 30%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.seg-btn:hover {
    cursor: pointer;
}
.seg-btn:hover > svg.seg-tel-icon{
    color: #75d2fd;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 80%);
    transform: scale(1.05);
}
.seguro_card-empty{
    padding: 0.5rem;
    width: 100%;
}
@media screen and (max-width: 1000px) {
    .seguro-container{
        display: block;
    }
    .seg-part{
        display: block;
        width: 100% !important;
    }
}
@media screen and (max-width: 490px) {
    .seg-data{
        display: block;
        min-width: 95%;
        max-width: 96%;
    }
    .seguro-company {
        width: auto;
    }
    .seg-data-content {
        display: block;
    }
    .seg-status {
        width: 100%;
        min-width: 95%;
        max-width: 96%;
    }
    .seg-data-content .form-group {
        width: 90%;
        margin: .25rem 0;
    }
    .seg-card.seg-contact {
        width: 100%;
        min-width: 95%;
        max-width: 96%;
    }
}