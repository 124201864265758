.section-cotizador{
    background-color: rgb(241,241,241);
    /* background: linear-gradient(to right, #d9e3ee 0%, #becfe0  100%); */
    background-size: 100%;
    background-position: bottom right;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding: 55px 0;
    height: 1000px;
    max-height: 1000px;
}
.papel_Cotizador{
    background-color: white;
    border-radius: 1rem;
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
    /* margin: 0 85% 0 15%; */
}
.form-control {
    display: block !important;
    margin: 1rem 0 !important;
}
.papel_Cotizador .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl {
    /* border: 1px solid rgba(0,0,0,.15); */
    background-color: rgba(0,0,0,.05);
    border-bottom: none;
    padding: 0.3rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 2.3rem !important;
}
.papel_Cotizador label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    padding-left: 0.4rem;
    font-size: 1.3rem !important;
    top: -.3rem;
}
.MuiInputBase-input {
    font-family: 'Montserrat', sans-serif !important;   
}
.MuiInputLabel-formControl{
    font-family: 'Montserrat', sans-serif !important;    
}
.MuiInputLabel-root.Mui-focused{
    color: #04a2eb !important;
}
.MuiButton-containedPrimary {
    background-color: #f1861a !important;
    font-family: 'Montserrat', sans-serif;
}
.MuiButton-containedPrimary:hover {
    background-color: #04a2eb !important;
}
.MuiButton-containedSecondary {
    background-color: #6590af !important;
    font-family: 'Montserrat', sans-serif;
}
.MuiButton-containedSecondary:hover {
    background-color: #6697f1 !important;
}
.disableButton {
    background: rgb(179, 179, 179) !important;
    color: black !important;
}
.disableButton:hover {
    background: rgb(179, 179, 179) !important;
    color: black !important;
    cursor: default;
}
.papel_Cotizador .MuiFormControl-root.MuiTextField-root.cotizador-input.input-money {
    background: rgba(0,0,0,.05);
    padding: 0.3em 0rem 0 0rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 2.3rem;
    width: 100%;
}
.papel_Cotizador .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    bottom: 0;
    height: 100%;
}
.number-format-errors p {
    font-size: .8rem;
    line-height: 14px;
    color: red;
}
.number-format-errors {
    width: 95%;
    margin-top: 0.2rem;
}
/********************************************************************/

.rowArea {
    display: flex;
}
.rowArea>.rowGroup {
    width: 50%;
    margin: 0 .60rem 0 .60rem;
}
.rowArea:first-of-type>.rowGroup {
    display: flex;
    margin-top: .60rem;
}
.rowArea>.rowGroup:first-of-type {
    margin-left: 0;
}
.rowArea>.rowGroup:last-of-type {
    margin-right: 0;
}
/*SELECT***********/
.selectForm {
    display: block !important;
    margin: 0  0 1rem 0 !important;
}
.selectForm>.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:before {
    content: "";
    display: none;
}
.selectInputCotizador{
    background-color: transparent !important;
}
.selectInputCotizador:focus{
    color: #005a96  !important;
}
.selectForm>.MuiInput-underline::after{
    content: "" !important;
    display: none;
}
.selectForm>.MuiInputBase-root {
    width: 100%;
}
a{
    text-decoration: none !important;
    color: #0275cb;
    font-weight: 600;
    transition: all .1s ease;
}
.forgot_password{
    display: block;
    margin-top: .5rem;
    text-align: left;
}
.register_in{
    display: block;
    /* margin-top: 1rem !important; */
}
a:hover{
    color: #f1861a;
}
a span{
    font-family: 'Montserrat', sans-serif;
    font-size: .95rem;
    font-weight: 500;
}
.MuiButton-label {
    font-family: 'Montserrat', sans-serif;
}
button#btnLogin {
    height: 2.5rem;
}
.cotizadorInputLabelSelect{
    margin-bottom: -.7rem;
}
.MuiInputBase-input.Mui-disabled {
    opacity: 1;
    color: rgba(0,0,0,.5);
}
.whatsapp-btn {
    display: block;
    width: 4.5rem;
    height: 2.5rem;
    background: rgb(27, 187, 91);
    border-radius: 15px;
    text-align: center;
    margin: .8rem auto;
    /* margin-top: 1.5rem; */
    transition: all .2s ease;
}
.whatsapp-btn:hover {
    background: rgb(19, 161, 76);
}
.whatsapp-btn svg {
    font-size: 1.5rem;
    margin-top: 0.45rem;
    color: white;
}
.cotizador_autosdatamovil .papel_Cotizador{
    width: 100% !important;
}
.cotizador_page_container{
    margin: 0 85% 0 15%;
}
/*CELULAR*******************************************/
  @media screen and (max-width: 565px) {
    .section-cotizador{
        padding: 15px 0;
    }
    .papel_Cotizador{
        margin: 0 auto;
        max-width: 90%;
    }
    .rowArea {
        display: block;
    }
    .sliderBar-width{
        width: 80% !important;
        margin: 1rem 1rem 1rem .3rem !important;
    }
    .sliderText-width{
        width: 20% !important;
        margin: 1rem 0 1rem 1rem !important;
    }
    .whatsapp-btn {
        display: block;
        width: 100%;
        border-radius: 8px;
    }
    .cotizador_page_container{
        margin: 0;
    }
  }
/*ANIMACIONES*********************************************/
.areaDatosPersona_visible{
    opacity:0;
    display: block !important;
   -moz-animation: toggledown .4s ease .2s forwards;
   -webkit-animation: toggledown .4s ease .2s forwards;
   -o-animation: toggledown .4s ease .2s forwards;
   -ms-animation: toggledown .4s ease .2s forwards;
    animation: toggledown .4s ease .2s forwards;
}
@-moz-keyframes toggledown /* Firefox */{
    0% {opacity: 0; margin-top: -10rem;}
    100% {opacity: 1;  margin-top: .5rem;}
}

@-webkit-keyframes toggledown /* Safari and Chrome */{
    0% {opacity: 0; margin-top: -10rem;}
    100% {opacity: 1;  margin-top: .5rem;}
}

@-o-keyframes toggledown /* Opera */{
    0% {opacity: 0; margin-top: -10rem;}
    100% {opacity: 1;  margin-top: .5rem;}
}

@-ms-keyframes toggledown /* IE10 */{
    0% {opacity: 0; margin-top: -10rem;}
    100% {opacity: 1;  margin-top: .5rem;}
}

@keyframes toggledown{
    0% {opacity: 0; margin-top: -10rem;}
    100% {opacity: 1;  margin-top: .5rem;}
}

.areaDatosPersona_hide{
    opacity:0;
    /* overflow: hidden; */
   -moz-animation: toggleup .4s ease .8s backwards;
   -webkit-animation: toggleup .4s ease .8s backwards;
   -o-animation: toggleup .4s ease .8s backwards;
   -ms-animation: toggleup .4s ease .8s backwards;
    animation: toggleup .4s ease .8s backwards;
}

@-moz-keyframes toggleup /* Firefox */{
    0% {opacity: 1; -moz-transform: scale(1);}
    40%  {-moz-transform: scale(0.9);}
    70% {opacity: 1; -moz-transform: scale(1);}
    100% {opacity: 0; -moz-transform: scale(0.5);}
  
}

@-webkit-keyframes toggleup /* Safari and Chrome */{
    0% {opacity: 1; -webkit-transform: scale(1);}
    40%  {-webkit-transform: scale(0.9);}
    70% {opacity: 1; -webkit-transform: scale(1);}
    100% {opacity: 0; -webkit-transform: scale(0.5); }
}

@-o-keyframes toggleup /* Opera */{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); }  
}

@-ms-keyframes toggleup /* IE10 */{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); }  
}

@keyframes toggleup{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); } 
}
