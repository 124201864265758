.section-logsign{
    background-color: #01468b;
    margin: 0 auto;
    padding: 90px 0;
}
.MuiInputBase-input {
    font-family: 'Open-Sans', sans-serif !important;    
}
.MuiInputLabel-formControl{
    font-family: 'Montserrat', sans-serif !important;    
}
.MuiInputLabel-root.Mui-focused{
    color: #04a2eb !important;
}
.MuiButton-containedPrimary {
    background-color: #f1861a !important;
    font-family: 'Montserrat', sans-serif;
}
.MuiButton-containedPrimary:hover {
    background-color: #04a2eb !important;
}
a{
    text-decoration: none !important;
    color: #0275cb;
    font-weight: 600;
    transition: all .1s ease;
}
.forgot_password{
    display: block;
    margin-top: .5rem;
    text-align: left;
}
.register_in{
    display: block;
    /* margin-top: 1rem !important; */
}
a:hover{
    color: #f1861a;
}
a span{
    font-family: 'Montserrat', sans-serif;
    font-size: .95rem;
    font-weight: 500;
}
.MuiButton-label {
    font-family: 'Montserrat', sans-serif;
}
.login-input input {
    border: none !important;
    width: 90% !important;
}
button#btnLogin {
    height: 2.5rem;
}