.portal-wrapper{
    margin-top: 80px;
    display: flex;
    min-height: calc(100vh - 80px);
    background-color: rgb(232,235,240);
}
.portalCont__ {
    position: absolute;
    right: 0;
    padding: 3rem;
    text-align: center;
    text-align: -webkit-center;
}
.sh-blue {
    background: #347ddd;
    background: linear-gradient(0deg, #347ddd 0%, #3974af 74%);
}
.sh-skyblue {
    background: #4891f0;
    background: linear-gradient(0deg, #4891f0 0%, #4685c4 74%);
}
.sh-darkblue {
    background: #1e5fb4;
    background: linear-gradient(0deg, #1e5fb4 0%, #154c94 74%);
}
.sh-darkgreen {
    background: #217813;
    background: linear-gradient(0deg, #217813 0%, #1d6612 74%);
}
.sh-gray {
    background: #7a7a7a;
    background: linear-gradient(0deg, #7a7a7a 0%, #616161 74%);
}
svg.go-back-icon {
    font-size: 2rem;
    color: darkgray;
    transition: all .1s ease;
}
svg.go-back-icon:hover{
    color: #1e5fb4;
}
.sh-offblue{
    background: #8faacc;
    background: linear-gradient(0deg, #8faacc 0%, rgb(112, 141, 170) 74%);
}
.option-title h1 {
    color: #04a2eb;
    font-size: 1.7rem;
}
@media screen and (max-width: 490px) {
    .option-title h1 {
        text-align: right;
        font-size: 1.5rem;
        line-height: 1;
        width: calc(100% - 3rem);
    }
}
.emtpy_Container {
    display: inline-block;
    width: 100%;
}
.float-left-cont{
    float: left;
    width: 100%;
}
input[type="text"], 
input[type="tel"], 
input[type="number"], 
input[type="password"],
input[type="email"],
textarea  {
    border: 1px solid var(--light_sky);
    outline: none;
    padding: 0.1rem 0.5rem;
    border-radius: 7px;
    width: 85%;
    /* min-width: 10rem; */
    margin: 0.2rem 0;
}
textarea{
    resize: none;
    height: 5rem;
}
.input-password {
    display: flex;
    width: 90%;
}
.MuiIconButton-root {
    padding: 4px !important;
    width: 15%;
}
.btn-blue {
    background: var(--blue);
    margin: 0;
    border: 10px solid var(--blue);
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: white;
    transition: all .2s ease;
    text-align: center;
    width: 100%;
    height: 40px;
}
.btn-blue:hover{
    border: 10px solid #0f5faf;
    background: #0f5faf; 
}
.btn-blue-disabled{
    border: 10px solid #0f5faf !important;
    background: #0f5faf !important;
}