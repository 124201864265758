:root{
    --sb_div: 25%;
    --pc_div: 75%;
    --sb_div_tablet: 45%;
    --pc_div_tablet: 55%;
    --sb_div_cel: 92.5%;
    --pc_div_cel: 5%;
}
.sidebar__ {
    position: fixed;
    height: calc(100% - 80px);
    background: white;
    /* height: inherit; */
    display: inline-block;
    width: var(--sb_div);
    -webkit-box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
    -moz-box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
    box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
    /* position: relative; */
    z-index: 200;
  }

  .sb_active{
    min-height: 100%;
    animation: slideShow 1.1s;
    left: 0%;
  }

  .sb_inactive{
    min-height: inherit;
    animation: slideHide 1.1s;
    left: calc(var(--sb_div) * -1 + 5px);
    position: fixed;
  }

  .pc_full{
    width: 100%;
  }

  .pc_div{
    width: var(--pc_div);
  }

  @keyframes slideHide {
    0%{
      left: 0;
    }
    100%{
      left: calc(var(--sb_div) * -1 + 5px);
    }
  }

  @keyframes slideShow {
    0%{
      left: calc(var(--sb_div) * -1 + 5px);
    }
    100%{
      left: 0;
    }
  }
  
  .sidebar__container {
    height: 100%;
    max-height: calc(100vh - 80px);
    display: block;
    padding: 1rem;
    overflow: auto;
  }
  
  .sidebar__logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transform: translate(5%, 0%);
  }
  
  .sidebar__icon {
    margin-right: 0.5rem;
  }

  .sidebar__sub-icon {
    color: rgb(87, 87, 87) !important;
    height: 3rem;
    font-size: 1.5rem;
    transition: all .15s ease;
    margin-left: auto;
  }
  
  .sidebar__nav-menu {
    list-style: none;
  }
  
  .sidebar__nav-item {
    height: 4.1rem;
    background-color: rgb(227, 230, 235);
    border-radius: 15px;
    margin: .3rem 0;
    padding: 0 1rem;
    transition: all .15s ease;
  }
  .sidebar__nav-item a{
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .sidebar__nav-item:hover {
    background-color: rgb(216, 220, 226);
  }

  .sidebar__nav-sub-item {
    height: 3.1rem;
    background-color: rgb(227, 230, 235);
    border-radius: 15px;
    margin: .3rem 0rem .3rem 1rem;
    padding: 0 1rem;
    transition: all .15s ease;
  }
  .sidebar__nav-sub-item a{
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .sidebar__nav-sub-item:hover {
    background-color: rgb(216, 220, 226);
  }

  .sidebar__nav-icons{
    color: rgb(87, 87, 87) !important;
    width: 10%;
    font-size: 1.5rem;
    align-self: center;
    margin-bottom: .3rem;
  }
  
  .sidebar__nav-links {
    color: rgb(87, 87, 87);
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
} 
  .sidebar__fa-bars {
    color: #fff;
  }
  
  .sidebar__menu-icon {
    display: none;
  }
  
  .sidebar__logo-datamovil-bar{
    width: 17rem;
    filter: invert(1);  
  }

  .resumenC__tagSlide {
    position: absolute;
    top: 0;
    width: 1.2rem;
    right: -18px;
    background: white;
    height: 2.6rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 5;
    -webkit-box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
  -moz-box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
  box-shadow: 4px 1px 8px -5px rgba(115,115,115,1);
}
.resumenC__tagSlide-tri{
  color: rgb(139, 139, 139) !important;
  height: 100%;
  width: 100%;
  margin-left: -2px;
  transition: all .15s ease;
}
.resumenC__tagSlide-tri:hover{
  cursor: pointer;
  color: #0f5faf !important;
}
 
.sidebar__separator{
  height: 8px;
  margin: 1rem .3rem .4rem .3rem;
  border-top: 2px solid rgb(216, 220, 226);
}
.optionSelected{
  border: 1px solid rgb(176, 181, 189);
  background: rgb(216, 220, 226);
}
.sb_inactive > .resumenC__tagSlide {
  width: 1.55rem;
  right: -24px;
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 910px) {
  .sidebar__ {
    width: var(--sb_div_tablet);
  }
  .sb_active{
    min-height: 100%;
    animation: slideShow 1.1s;
    left: 0%;
  }

  .sb_inactive{
    min-height: inherit;
    animation: slideHide 1.1s;
    left: calc(var(--sb_div_tablet) * -1 + 5px);
    position: absolute;
  }

  .pc_full{
    width: 100%;
  }

  .pc_div{
    width: var(--pc_div_tablet);
    position: absolute;
    width: 100%;
  }

  @keyframes slideHide {
    0%{
      left: 0;
    }
    100%{
      left: calc(var(--sb_div_tablet) * -1 + 5px);
    }
  }

  @keyframes slideShow {
    0%{
      left: calc(var(--sb_div_tablet) * -1 + 5px);
    }
    100%{
      left: 0;
    }
  }

  .sidebar__nav-item {
    height: 3.5rem;
  }
  li.sidebar__nav-item:last-of-type {
    margin-bottom: 1rem;
  }
}
  /*MOBILE CEL*******************************************/
  @media screen and (max-width: 520px) {
    .sidebar__ {
      width: var(--sb_div_cel);
    }
    .sb_active{
      min-height: 100%;
      animation: slideShow .3s linear;
      left: 0%;
    }
  
    .sb_inactive{
      min-height: inherit;
      animation: slideHide .3s linear;
      left: calc(var(--sb_div_cel) * -1 + 5px);
      position: fixed;
      /* top: 10.15rem; */
    }
  
    .pc_full{
      width: 100%;
    }
  
    .pc_div{
      width: var(--pc_div_cel);
      position: absolute;
      width: 100%;
    }
  
    @keyframes slideHide {
      0%{
        left: 0;
      }
      100%{
        left: calc(var(--sb_div_cel) * -1 + 5px);
      }
    }
  
    @keyframes slideShow {
      0%{
        left: calc(var(--sb_div_cel) * -1 + 5px);
      }
      100%{
        left: 0;
      }
    }

    .sidebar__nav-item {
      height: 3.5rem;
    }
  }
  /* Works on Firefox */
.sidebar__container {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
.sidebar__container::-webkit-scrollbar {
  width: 12px;
}

.sidebar__container::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.sidebar__container::-webkit-scrollbar-thumb {
  background-color: rgb(201, 201, 201);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}