.cotizador-input input {
    border: none !important;
    width: 90% !important;
}
.cotizadorInputLabel {
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin: .3rem auto;
    margin-left: .3rem;
}
.rowInput {
    margin: 1.3rem 0;
}
.rowGroup {
    margin: .60rem 0;
}
.selectInputCotizador span, li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button span {
    color: gray;
    font-size: .9rem;
    margin-left: .3rem;
}
/*CELULAR*******************************************/
@media screen and (max-width: 565px) {
    .rowGroup {
        width: 100% !important;
        margin: 1.2rem 0 !important;
    }
}