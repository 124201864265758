.fpago_container{
    display: block;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
}
.fpago_options{
    border: 1px solid rgba(63, 95, 143, 0.2);
    border-radius: 10px;
    background: rgba(63, 95, 143, 0.1);
    padding: .4rem;
    margin: .6rem 0;
}

.fideicomiso {
    color: rgba(128, 128, 128, 0.634);
    width: 100%;
}

.mensaje_fideicomiso {
    margin-right: 65% !important;
    margin-top: 10px !important;
    font-size: 14px;
}