.contratosC_ h1{
    color: #2d6192;
    font-size: 1.5rem;
    text-align: left;
    padding-left: .4rem;
    font-family: 'Montserrat', sans-serif;
}
.contratosC_ .form{
    margin: .3rem;
    display: flex;
    float: left;
    background: white;
    width: 99%;
    max-width: 1190px;
    padding: 1rem 13%;
    border-radius: 10px;
    box-shadow: 0px 0px 30px -23px rgb(26 26 26);
}
.groupC {
    border: 1px solid rgba(63, 95, 143, 0.2);;
    padding: 0.5rem;
    border-radius: 10px;
    background: rgba(63, 95, 143, 0.1);;
}
.contratosC_ .form>:nth-child(1){
    width: 35%;
    margin: .5rem;
} 
.contratosC_ .form>:nth-child(2){
    width: 45%;
    margin: .5rem;
}
.contratosC_ .form>:nth-child(3){
    width: 20%;
    margin: .5rem;
}
input.referencia {
    width: 6rem;
    text-align: center;
}
.contratosC_ .form>:nth-child(2){
    justify-content: space-evenly;
}
.contratosC_  label{
    width: 90%;
    display: block;
    font-family: 'Montserrat', sans-serif;
    color: #33485c;
    /* margin-bottom: 0.5rem; */
    font-weight: 500;
    min-height: 40px;
}
.contratosC_ input, .contratosC_ select{
    outline: none;
    padding: .3rem;
    padding-left: .45rem;
    border: 1px solid #7a8691;
    border-radius: 7px;
    font-family: 'Montserrat', sans-serif;
}
.contratosC_ select{
    padding: .25rem;
}
.fechaC {
    display: flex;
    justify-content: space-evenly;
}
.form-group{
    align-self: center;
    text-align: center;
}
/* .contratosC_ .form-group:last-of-type{
    align-self: baseline;
} */
.addC_btn {
    background: var(--blue);
    margin: 0;
    border: 10px solid var(--blue);
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: white;
    transition: all .2s ease;
    padding: 1.5rem 0;
    text-align: center;
}
.addC_btn:hover{
    cursor: pointer;
    background: #33485c;
    border: 10px solid #33485c;
}
.selectMes select {
    width: 7.6rem;
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 1050px) {
    .contratosC_ .form{
        display: block;
        width: auto;
        text-align: left;
        padding: 1rem;
        width: 25rem;
    }
    .contratosC_ .form>:nth-child(1),.contratosC_ .form>:nth-child(2),.contratosC_ .form>:nth-child(3){
        width: 85%;
        margin: 1rem auto;
    }
    .contratosC_ .form-group{
        text-align: left;
    }
    .contratosC_  label{
        min-height: auto;
        margin-bottom: .3rem;
    }
    .contratosC_ .form>:nth-child(3){
        padding: .1rem 0;
    }
    .fechaC {
        justify-content: space-between;
    }
    
}
  /*MOBILE CEL*******************************************/
  @media screen and (max-width: 520px) {
    .contratosC_ .form{
        display: block;
        width: auto;
        text-align: left;
        padding: 2rem;
        width: 95%;
    }
    .contratosC_ input, .contratosC_ select {
        width: 100%;
        text-align: center;
    }
    .fechaC {
        display: inline-block;
        width: 100%;
    }
    .fechaC .form-group {
        width: 100%;
        margin-bottom: .3rem;
        text-align: center;
    }
    .contratosC_ h1 {
        text-align: center;
    }
  }