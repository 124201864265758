#cotizadorHeroBg{
    background-position: bottom right;
    background-size: cover;
    margin: 0;
    padding: 0rem;
    display: block;
}
#cotizadorHeroBg div form .papel_Cotizador{
    margin: 0;
}
@media screen and (max-width: 565px){
    #cotizadorHeroBg div form .papel_Cotizador{
        margin: 0 auto;
        width: 100% !important;
        max-width: 100% !important;;
    }
    #cotizadorHeroBg .col-calculadoraCredito{
        padding-right: 0px;
        padding-left: 0px;
    }
}