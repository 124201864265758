.requisitos__section {
    padding: 1px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }
  
  .requisitos__container-card {
    background: linear-gradient(45deg, #0660e9 0%, #276afb 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;
    margin: 0 7px;
  }
  
  /* .requisitos__container-card:nth-child(2) {
    transform: scale(1.05);
    background: #242424;
  } */
  
  .requisitos__container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  
  /* .requisitos__container-card:nth-child(2) {
    margin: 0 24px;
  } */
  
  .requisitos__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .requisitos__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
  }
  
  .requisitos__heading {
    color: #1c2237;
    margin-bottom: 24px;
    align-self: center;

  }
  
  .requisitos__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: #fff;
  }
  
  .requisitos__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  
  .requisitos__container-cardInfo h4 {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .requisitos__container-cardInfo p {
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  .requisitos__container-features {
    margin: 16px 0 32px;
    list-style: inside;
  }
  
  .requisitos__container-features li {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .requisitos__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }
  
  .icon {
    margin: 24px 0;
  }
  
  @media screen and (max-width: 960px) {
    .requisitos__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .requisitos__container-card {
      width: 90%;
      margin-bottom: 1rem;

    }
  
    .requisitos__wrapper {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    /* .requisitos__container-card:nth-child(2) {
      transform: scale(1);
      background: #242424;
      margin: 24px;
    } */
  
    .requisitos__container-card:hover {
      transform: none;
    }
  }

/* Works on Firefox */
.requisitos__container-features {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
.requisitos__container-features::-webkit-scrollbar {
  width: 12px;
}

.requisitos__container-features::-webkit-scrollbar-track {
  background: rgb(17, 70, 150);
  border-radius: 10px;
}

.requisitos__container-features::-webkit-scrollbar-thumb {
  background-color: rgb(94, 158, 231);
  border-radius: 20px;
  border: 2px solid rgb(17, 70, 150);
}
  /*CELULAR*******************************************/
  @media screen and (max-width: 420px) {
    .requisitos__section {
      margin-top: 4rem;
    }
  }
