.button-opciones{
    transition: all .1s ease;
    height: 90px;
    background: white;
    margin: 1rem;
    padding: 0 1rem;
    border-radius: 13px;
    -webkit-box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 30%);
    display: flex;
    max-width: 1300px;
}
.button-opciones:hover{
    cursor: pointer;  
    -webkit-box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 75%);
}
.button-opciones:hover > p{
    color: #33a9d4; 
}
.button-opciones p{
    color: #0f5faf; 
    text-align: left;
}
.opciones-links{
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.opciones_img {
    position: relative;
    width: 70px;
}
.opciones_circle {
    width: 3.5rem;
    max-height: 3.5rem;
    height: -webkit-fill-available;
    border-radius: 15px;
    margin: 0.65rem;
    position: absolute;
    top: calc(50% - 2.55rem);
    left: calc(50% - 2.3rem);
    z-index: 0;
}
.sh-dark_blue{  
    background: #0e60b3;  
    background: #0e60b3;  
    background: #0e60b3;
    background: #0e60b3; 

    -webkit-transition: all .5s ease-out;  
    -moz-transition: all .5s ease-out;  
    -o-transition: all .5s ease-out;  
    transition: all .5s ease-out;  
}  

.button-opciones:hover > .opciones_img >.sh-dark_blue{  
    background: #3e92ca;  
    background: #3e92ca;  
    background: #3e92ca;
    background: #3e92ca;
} 
/* .sh-dark_blue {
    background: #01468b;
    background: linear-gradient(
0deg, #276afb 0%, #01468b 100%);
} */
.opciones_circle h1 {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: .75rem;
    text-align: center;
    display: block;
    top: 35%;
    position: absolute;
    margin: 0;
    width: 100%;
}
.opcion-icon {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    display: block;
    top: calc(50% - 1rem);
    position: absolute;
    margin: 0;
    width: 100%;
}
.banner{
    margin: -1rem 1.1rem 2rem 1.1rem;
    text-align: center;
    max-width: 1300px;
}
.banner img{
    border-radius: 15px;
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 810px) {
    .portalCont__ {
        padding: 2rem;
    }
    .banner {
        margin: 1rem 1.1rem 2rem 1.1rem;
    }
    .banner img{
        border-radius: 7px;
    }
}
/*MOBILE CEL*******************************************/
@media screen and (max-width: 520px) {
    .portalCont__ {
        padding: 1rem;
    }
    .banner {
        margin: 1rem 1.1rem 2rem 1.1rem;
    }
    .banner img{
        border-radius: 7px;
    }
}