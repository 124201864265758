.navbar_portal__ {
    background: #01468b;
/* background: linear-gradient(0deg, rgba(0,212,255,0) 0%, rgba(4,4,43,0.5) 90%, rgba(5,3,34,0.6) 100%); */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  
  .navbar_portal__container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0 1rem;
    place-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 95%;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar_portal__logo {
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transform: translate(5%, 0%);
  }
  
  .navbar_portal__icon {
    margin-right: 0.5rem;
  }
  
  .navbar_portal__nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: center;
  }
  
  .navbar_portal__nav-item {
    height: 80px;
    margin: 0 .5rem;
    transition: all .3s ease;
  }

  .navbar_portal__nav-icons{
    font-size: 2rem;
    display: block;
    height: 100%;
  }

  svg.navbar_portal__nav-icon {
    height: 100%;
    transition: all .15s ease;
}

.navbar_portal__nav-icon:hover{
  color: #02adeb !important;
  cursor: pointer;
}
  
  .navbar_portal__nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    transition: all .15s ease;
} 
.navbar_portal__nav-links:hover {
  color: #02adeb !important;
  cursor: pointer;
} 
  /*CELULAR*******************************************/
  @media screen and (max-width: 420px) {
    #navBar_nombre_usuario{
      display: none;
    }
  }

