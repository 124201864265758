.selectFLugar__ {
    display: flex;
    height: 2.5rem;
    padding: .3rem .4rem;
    flex: 2;
    flex-grow: 0;
  }
  .selectFLugar__label {
    width: 58%;
    align-self: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: left;
    color: rgb(70, 70, 70);
  }
  .selectFLugar__combo {
    width: 42%;
    align-self: center;
    height: 100%;
  }
  .selectFLugar__combo select {
    outline: none;
    border: none;
    padding: 0 .3em 0 .3rem;
    margin: 0;
    line-height: inherit;
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    background-color: rgb(216, 220, 226);
    border-radius: 10px;
    color: #0f5faf;
    border-right: 0.5rem solid rgb(216, 220, 226);
  }
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 810px) {
    .selectFLugar__ {
        display: block;
        height: auto;
    }
    .selectFLugar__label {
        width: 100%;
      }
      .selectFLugar__combo {
        width: 100%;
      }
}
  /*MOBILE CEL*******************************************/
  @media screen and (max-width: 520px) {
    
  }