
.formHeaderCotizador{
    /* background-color: blue; */
    background: linear-gradient(to right, #144288  0%, #0e59a0  100%);
    padding: 1.3rem 1.3rem;
    width: calc(100% + 61px);
    margin-top: -1.9rem;
    margin-left: -1.9rem;
    margin-bottom: .6rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 145px;
}
.formHeaderCotizador>.headerTopTitle{
    color: white;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}
.formHeaderCotizador>.headerTitle{
    display: flex;
    margin-top: .3rem;   
}
.formHeaderCotizador>.headerTitle *{
    color: white;
    font-family: 'Montserrat';
    font-size: 3.5rem; 
}
.formHeaderCotizador>.headerTitle h3{
    text-align: center;
    width: 60%;
    /* display: flex; */
}
.formHeaderCotizador>.headerTitle h3 p {
    display: inline;
    font-size: 2.3rem;
    align-self: center;
    place-self: center;
    margin-right: 0.3rem;
}
.formHeaderCotizador>.headerTitle span{
    width: 40%;
    font-size: 1.5rem;
    margin-right: .2rem;
    align-self: self-end;
    margin-bottom: .4rem;
    text-align: center;
}
.errorCalculo-heading{
    font-size: 2.5rem !important; 
    width: 100% !important; 
    text-align: center !important;
    color: rgb(255, 136, 40) !important;
    text-shadow: 1px 1px 10px rgba(0,0,0,.9);
}
/*CELULAR ********************************/
@media screen and (max-width: 565px) {
    .section-cotizador{
        padding: 15px 0;
        max-height: 2500px;
        height: auto;
    }
    .formHeaderCotizador>.headerTitle{
        display: flex;
        margin-top: .3rem;  
    }
    .formHeaderCotizador>.headerTopTitle{
        font-size: .9rem; 
    }
    .formHeaderCotizador>.headerTitle h3{
        font-size: 2.4rem; 
        width: 100%;
    }
    .formHeaderCotizador>.headerTitle span{
        display: block;
        font-size: 1rem;
        width: 100%;
        margin: 0 0 .3rem .3rem;
        text-align: right;
    }
    .formHeaderCotizador {
        height: 125px;
    }
}