.pago-container {
  display: flex;
}
.efectivo-content{
    display: block;
    margin-top: 1rem;
    width: 55%;
}
.ficha-deposito {
  margin: 1rem;
  margin-right: 0;
  /* padding: 0.5rem; */
  width: 45%;
  /* border: 2px solid rgb(124, 124, 124); */
  border-radius: 10px;
}
.ficha-deposito h1 {
  background: rgba(149, 166, 173,.5);
  border: 1px solid rgb(149, 166, 173);
  /* border-bottom: 0; */
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 1.3rem;
  padding: 0.9rem;
  color: #3b84cc;
}
.fd-content {
  padding: 1rem;
  height: 86%;
  border: 1px solid rgb(149, 166, 173);
  border-top: 0;
  background: rgba(149, 166, 173,.35);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.panel-heading {
    padding: 6px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.panel-blue {
    display: block;
    background: #004C97 !important;
    color: #FFF !important;
}
img.imagen_logo, .logotransfer p {
    margin: auto;
    padding: 0.2rem 0;
    text-align: center;
    font-size: 1.1rem;
    width: auto;
}
.imagen_logo {
    vertical-align: middle;
    display: block;
    margin: auto;
}
.table-r {
    margin: 0;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    border-collapse: collapse;
}
.table-r .thead, .table-r tfoot {
    background-color: #f0f0f0;
    color: #111;
}
.table-r .thead {
    width: 100%;
    /* border: 1px solid #a7a7a7; */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 0 !important;
}
.table-r .thead:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.table-r .thead th.t_header, .table-r tfoot th.t_header {
    text-align: center;
    background-color: var(--lightcyan);
}
.table-r .thead th, .table-r tfoot .th {
    font-size: 17px;
    font-weight: 500;
    padding: 0px;
}
.t_header1.alinear_centro {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.table-r .tbody {
    background-color: #f9f9f9;
    color: #444;
}
.table-r .td.t_footer, .table-r .td.t_footer {
    text-align: center;
}
.table-r .thead th.t_header1, .table-r tfoot th.t_header1 {
    text-align: center;
    background-color: var(--lightcyan);
}
.table-r .td.t_footer1, .table-r .td.t_footer1 {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    text-align: center;
}
.table-r .thead th, .table-r tfoot .th {
    font-size: 17px;
    font-weight: 500;
    padding: 0px;
}
.table-r .tbody .td, .table-r .tbody .t_footer {
    padding: 8px;
    /* border: 1px solid #a7a7a7; */
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.td.t_footer {
    font-size: 20px;
}
.sh-banorte{
    background: rgb(226,0,50);
}
.back-banorte{
  background: rgb(255, 158, 158);
}
.border-banorte{
  /* border: 1px solid rgb(226,0,50) !important; */
  background: rgba(226,0,50,.1);
}
.sh-ahorro {
    background: white;
    border: 1px solid #0F406F !important;
}
.back-ahorro{
  background: #97b3cc;
}
.border-ahorro{
  /* border: 1px solid #0F406F !important; */
  background: #c7dff5;
}
.sh-seven{
    background: rgb(0,136,90);
}
.back-seven{
  background: rgb(141, 207, 185);
}
.border-seven{
  /* border: 1px solid rgb(0,136,90) !important; */
  background: rgba(0,136,90,.2);
}
.sh-banorte_web{
  background: rgb(226,4,116);
}
.sh-spei{
  background: rgb(27,44,116);
}
/* TRANSFERENCIAAAS */
.logotransfer {
    height: 3rem;
    width: 100%;
    /* background-color: #0F406F; */
    margin: 1rem 0 0 0;
    border-radius: 10px;
    padding: 0.2rem;
}
ol.step-list > li::before,ol.step-list  > li {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  }
  
  ol.step-list  {
    counter-reset: gradient-counter;
    list-style: none;
    margin: .8rem 0;
    padding-left: 1rem;  
  }
  
  ol.step-list  > li {
    background: rgba(223,223,223);
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: .6rem .6rem .6rem 3.2rem;
    position: relative;
    outline-offset: -1px;
    max-width: 590px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: left;
    padding-left: 4rem;
  }
  
  ol.step-list  > li::before,ol.step-list  > li::after {
    content: "";
    left: -1rem;
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 3rem;
    background-color: grey;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    clip-path: polygon(0 110%,    0% 0,   0% 0%,    70% 0%,   100% 50%,    70% 100%,    0% 200%,    0% 00%,    10% 10%);
  }
  ol.step-list  > li::before {
    align-items: center;
    content: counter(gradient-counter);
    color: #1d1f20;
    display: flex;
    font-family: 'Open Sans';
    font-size: 1.4em;
    font-weight: 700;
    justify-content: flex-start;
    margin-left: 6px;
    padding: 0.125em 0.25em;
    z-index: 1;
    color: white;
    height: 100% ;
    /* min-height: 100%; */
    max-height: -webkit-fill-available;
  }
  
ol.step-list  > li + li {
  margin-top: 1rem;
}
ol.step-list  > li > span{
  position: relative;
  background-color: rgb(243, 243, 243);
  padding: .3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
  width: 80%;
  min-width: 126px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
ol.step-list  > li > span > p{
  margin: 0 .2rem;
  font-size: 1rem;
  font-weight: 600;
}

.copyClip{
  display: block;
  position: absolute;
  right: -2.5rem;
  background: rgb(243, 243, 243);
  border: 4px solid rgb(223,223,223);
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  padding: inherit;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
}

.copyClip svg{
  width: 75%;
  filter: brightness(.4);    
  transition: all .2s ease-in-out;
  filter: invert(1) brightness(0.3);
  margin-top: -.2rem;
}

.logotransfer {
  height: 3rem;
  width: 100%;
  /* background-color: #0F406F; */
  margin: 1rem 0 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: .2rem;
  max-width: 610px;
}
img.imagen_logo, .logotransfer p {
  margin: auto;
  padding: .2rem 0;
  text-align: center;
  font-size: 1.1rem;
}
.info-box{
  border: solid 1px #326dbf;
  padding: .5rem;
  border-radius: 10px;
  margin-bottom: -0.8rem;
}
.info-cont{
  margin:0;
}
.info-cont ul{
  margin-top: .75rem;
  margin-bottom: 0;
  list-style: disc;
  padding-left: 2rem;
}
.info-cont ul li{
  line-height: 1.1;
}
.info-cont h2{
  font-size: 1.2rem;
  margin: 0.2rem;
  color: #326dbf;
  font-weight: 700;
}
.estadoC__fichas{
  background: none;
  box-shadow: none;
  min-height: 14rem;
  height: 75%;
  overflow-y: auto;
}
.estadoC__fichas thead tr {
  position: relative;
}
.estadoC__fichas th {
  position: sticky;
  top: 0px;
}
.estadoC__fichas table tbody tr{
  border-bottom: 1px solid #7a7a7a;
  box-shadow: none;
}
/* .selectPeriodos select {
  outline: none;
  border: none;
  padding: 0 0.3em 0 0.3rem;
  margin: 0;
  line-height: inherit;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: white;
  border-radius: 10px;
  color: #0f5faf;
  border-right: 0.5rem solid white;
}
*/
.periodos_options {
  display: flex;
  justify-content: space-evenly;
}
.periodos_options label {
  align-self: center;
}
input.num_fichas {
  width: 4rem;
}
  /* Works on Chrome, Edge, and Safari */
.estadoC__fichas::-webkit-scrollbar {
  width: 12px;
}

.estadoC__fichas::-webkit-scrollbar-track {
  background: rgba(216, 226, 231, 0);
  border-radius: 10px;
}

.estadoC__fichas::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 20px;
  border: 3px solid #919191;
}
.btnDescargarFicha{
  width: 35%;
  font-size: 0.8rem;
}
.selected-row{
  background-color: rgb(193, 220, 231);
}
.info-box {
  border: solid 1px #326dbf;
  padding: 0.5rem;
  border-radius: 10px;
  margin-bottom: -0.8rem;
  max-width: 610px;
  text-align: left;
  margin-bottom: .45rem;
}
.info-box {
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  background-color: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: 0.5rem;
  position: relative;
  width: 100%;
}
.info-cont {
  margin: 0;
}
.info-cont h2 {
  font-size: 1.2rem;
  margin: 0.2rem;
  color: #326dbf;
  font-weight: 700;
}
.info-cont ul {
  margin-top: 0.75rem;
  margin-bottom: 0;
  list-style: disc;
  padding-left: 2rem;
}
.info-cont ul li {
  line-height: 1.1;
}
.horario-efectivo-tr {
  border: 1px solid var(--light-blue);
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
  border-bottom: none;
}
.horario-efectivo-tr:last-of-type{
  border-bottom: 1px solid var(--light-blue);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}