
/**verify_navbar********************************************************/
.verify_navbar {
    background: rgb(230, 233, 240);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}
.verify_logo-datamovil-bar {
    width: 11rem;
    filter: invert(1) brightness(0.5) sepia(10) saturate(10) hue-rotate(200deg) saturate(5) brightness(.45);
}
.verify_container {
    z-index: 1;
    width: 95%;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
}
.verify_navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}
.verify_navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transform: translate(5%, 0%);
}
.verify_nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: center;
}
.verify_nav-item {
    border-bottom: 2px solid transparent;
    transition: all .3s ease;
}
.verify_nav-links {
    color: var(--blue-dark);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.section-verifymail {
    background-color: rgb(223, 226, 233);
    margin: 0 auto;
    padding: 90px 0;
    min-height: 100vh;
}
#reenviarCodigo{
    font-weight: 700;
    transition: .15s all ease;
}
#reenviarCodigo:hover{
    cursor: pointer;
}

@media screen and (max-width: 420px){
    .verify_navbar {
        height: 60px;
    }
    .verify_navbar-container {
        height: 60px;
    }
    .verify_navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5%, 20%);
    }
    .verify_nav-menu {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        list-style: none;
        text-align: center;
        justify-content: right;
    }
    .verify_nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
}