.footer-container {
    background-color: #111318;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-links {
    width: 97%;
    max-width: 97%;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 16rem;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a, .footer-link-items p {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
    transition: all .2s ease-in-out;
  }
  .social-icon-link:hover{
      color: rgb(0, 119, 255);
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    position: relative;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      display: block;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }

  .logo-datamovil-footer{
    width: 12rem;
    height: 2.7rem;
    filter: invert(1);  
  }
  .download-app-img{
    width: 8rem;
    height: 2.5rem;
  }

/*CELULAR*******************************************/
@media screen and (max-width: 420px) {
  .footer-link-items {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    align-items: center;
    padding: 0 .3rem;
    text-align: center;
  }
  .footer-link-items > h2 {
    text-align: center;
    font-size: 1.3rem;
  }
  .footer-link-items a, .footer-link-items p {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.15rem;
    font-size: .9rem;
  }
  .footer-link-items p {
    font-size: .8rem;
  }
  .footer-link-items p:first-child{
    font-size: .7rem;
  }
}