.navbar {
    background: rgba(3, 51, 99, 1);
    /* background: linear-gradient(180deg, rgb(0, 13, 36) -30%, rgba(0, 38, 96, 0) 125%); */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: all .5s ease-in-out;
  }
  .navbar-fixed{
    position: fixed;
    width: 100%;
    background: rgba(3, 51, 99, 1);
    background: linear-gradient(180deg, rgb(0, 13, 36) -30%, rgba(0, 38, 96, 0) 125%);
  }
  .navbar-scroll {
    background: rgba(3, 51, 99, 1);
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 95%;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transform: translate(5%, 0%);
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: center;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
    transition: all .3s ease;
  }
  
  .nav-item:hover {
    border-radius: 3px;
    border-bottom: 3px solid #f1861a;
    transform: translateY(3px);
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
} 
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  .logo-datamovil-bar{
    width: 17rem;
    height: 100%;
    filter: invert(1);  
  }
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 95vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(15%, 20%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }

  }

  /*CELULAR*******************************************/
  @media screen and (max-width: 420px) {
    .navbar{
      height: 60px;
    }
    .logo-datamovil-bar{
      width: 11rem;
      filter: invert(1);  
    }
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(5%, 20%);
    }
    .menu-icon {
      transform: translate(-80%, 30%);
    }
    .navbar-container {
      height: 60px;  
    }
    .nav-menu {
      top: 60px;
    }
  }