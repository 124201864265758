.option-section {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 50%);
}
.option-container {
    padding: 1rem;
}
.option-title {
    width: 100%;
    display: flex;
    padding: .25rem;
    padding-bottom: 1rem;
}
.option-title a {
    width: 3rem;
    align-self: center;
    text-align: left;
    padding-top: .2rem;
}
.option-title h1 {
    color: #04a2eb;
    font-size: 1.7rem;
}
.estadoC__simbologia {
    max-width: 1000px;
}
.estadoC__simbologia .semaforo {
    display: flex;
    flex: 1;
    flex-basis: 33%;
}
.estadoC__simbologia .semaforo .sem-type {
    display: flex;
    align-items: center;
    align-content: center;
    width: 33.33%;
    align-self: center;
    text-align: center;
    justify-content: center;
    place-content: center;
}
.estadoC__simbologia .semaforo .sem-type .sem-circle-cont {
    width: 15%;
}
.estadoC__simbologia .semaforo .sem-type .sem-circle {
    float: right;
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
    background: white;
}
.estadoC__simbologia .semaforo .sem-type p {
    width: auto;
    margin: .1rem .4rem;
}
.sem-yellow {
    background: rgb(70, 69, 67) !important;
}
.sem-red {
    background: rgb(204, 58, 47) !important;
}
.sem-green {
    background: rgb(51, 155, 51) !important;
}
.estadoC__simbologia .low-message p {
    margin: .1rem 1.4rem .7rem 0;
    text-align: right;
    font-size: .8rem;
    color: #195ba7;
}
/*TABLE******/
.estadoC__table {
    background: #347ddd;
    width: 93%;
    max-width: 1000px;
    border-radius: 10px;
    padding-bottom: 0px;
    -webkit-box-shadow: 0px 1px 0px 2px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 1px 0px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 0px .5px #347ddd;
}
.estadoC__table table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    width: 99.8%;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 0px 25px -15px rgb(26 26 26);
}
.estadoC__table table thead tr {
    height: 2rem;
    font-size: 16px;
}
.estadoC__table table thead td, .estadoC__table table thead th {
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: .8rem;
}
.estadoC__table table td, .estadoC__table table th {
    text-align: center;
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: .7rem;
    padding: 0 0.25rem;
}
.estadoC__table table * {
    position: relative;
}
.estadoC__table table tbody tr {
    height: 2.55rem;
    border-bottom: 1px solid #347ddd;
    box-shadow: 0 0 1px 0px #347ddd inset, 0 0 1px 0px #347ddd;
}
.estadoC__table table tbody tr:last-of-type{
    border-bottom: none;
}
.estadoC__table table .green-col td, .estadoC__table table .green-col th {
    color: rgb(51, 155, 51) !important;
}
.estadoC__table table .yellow-col td, .estadoC__table table .green-col th {
    color: rgb(70, 69, 67) !important;
}
.estadoC__table table .red-col td, .estadoC__table table .green-col th {
    color: rgb(204, 58, 47) !important;
}

/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
    .heading {
        font-size: 2rem;
    }
    .home__hero-section {
        padding: 4rem 2%;
    }
    .home__hero-container {
        width: 100%;
        padding: 1rem ;
    }
    .estadoC__table {
        width: 100%;
    }
}

.fideicomiso {
    color: rgba(128, 128, 128, 0.634);
    width: 100%;
}

.mensaje_fideicomiso {
    margin-right: 65% !important;
    margin-top: 10px !important;
    font-size: 14px;
}