.dep-all-container {
    display: flex;
}
.option-subtitle h1 {
    font-size: 1.3rem;
    padding: 0.9 rem;
    color: #3b84cc;
}
.dep-type{
    width: 50%;
}
.depositos__today {
    width: 100%;
    display: block;
    height: 3.7rem;
}
.depositos-container{
    width: 93%;
    max-width: 1000px;
}
.dep__card{
    display: flex;
    height: 100%;
    margin: .5rem;
    background: rgb(230, 230, 230);
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 20px -15px rgb(0, 0, 0);
    -moz-box-shadow: 0px 1px 20px -15px rgb(0, 0, 0);
    box-shadow: 0px 1px 20px -15px rgb(0, 0, 0);
}
.dep__card-data{
    padding: .35rem .85rem;
}
.dep__card-today{
    position: relative;
}
.dep__card-today .dep__card-data{
    width: 50%;
    height: 100%;
}
.dep__card-today>.dep__card-monto{
    position: absolute;
    width: 40%;
    height: 100%;
    right: 0%;
    background: #babec2;
    clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);
    border-bottom: 1px solid #babec2;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dep__card-fecha{
    display: flex;
    width: 40%;
}
.dep__card-monto{
    position: relative;
}
.dep__card-monto p{
    position: absolute;
    margin: 0;
    margin-left: 29%;
    text-align: center;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    height: 50%;
    top: 25%;
    text-shadow: 0px 0px 4px #ffffff;
}
.dep__card-today .dep__card-fecha h1{
    width: 40%;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
}
.dep__card-today .dep__card-fecha p{
    width: 60%;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    margin-top: .2rem;
    text-align: center;
}
.dep__card-last10.dep__card{
    position: relative;
}
.depositos__last10 .last10_warning{
    font-family: 'Open Sans';
    font-size: 12px;
    color: var(--black);
    font-weight: 600;
    margin-left: 10px;
}
.dep__card-last10>.dep__card-monto{
    position: absolute;
    width: 40%;
    height: 100%;
    right: 35%;
    background: #babec2;
    clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);
    border-bottom: 1px solid #babec2;
}
.dep__card-last10>.dep__card-monto-sc{
    position: absolute;
    width: 44%;
    height: 100%;
    right: 30%;
    background: #babec2;
    clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);
    border-bottom: 1px solid #babec2;
}
.dep__card-last10>.dep__card-xml{
    width: 15%;
}
.dep__card-last10>.dep__card-xml-sc{
    width: 20%;
}
.dep__card-last10>.dep__card-pdf{
    width: 15%;
}
.dep__card-last10>.dep__card-pdf-sc{
    width: 20%;
}
.dep__card-last10>.dep__card-pdf-c{
    width: 15%;
}
.dep__card-last10>.dep__card-pdf-text{
    width: 43%;
}
.dep__card-last10>.dep__card-xml{
    position: absolute;
    height: 100%;
    right: 23%;
    clip-path: polygon(20% 0%, 100% 0, 80% 100%, 0% 100%);
}
.dep__card-last10>.dep__card-xml-sc{
    position: absolute;
    height: 100%;
    right: 15%;
    clip-path: polygon(20% 0%, 100% 0, 80% 100%, 0% 100%);
}
.dep__card-xml p {
    position: absolute;
    right: 0;
    width: 50%;
    text-align: center;
    color: white;
    font-weight: 500;
    line-height: 15px;
    align-self: center;
    margin: 0;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
}
.dep__card-last10>.dep__card-pdf{
    position: absolute;
    height: 100%;
    right: 12%;
    clip-path: polygon(20% 0%, 100% 0, 80% 100%, 0% 100%);
}
.dep__card-last10>.dep__card-pdf-sc{
    position: absolute;
    height: 100%;
    right: 0%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}
.dep__card-last10>.dep__card-pdf-c{
    position: absolute;
    height: 100%;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}.dep__card-last10>.dep__card-pdf-text{
    position: absolute;
    height: 100%;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}
.dep__card-last10>.dep__card-fecha h1{
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    width: 2.75rem;
}
.dep__card-last10>.dep__card-fecha p {
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    margin-left: 1rem;
    line-height: 1;
    align-self: center;
}
.dep__card-last10>.dep__card-monto p {
    margin-left: calc(50% - 5rem);
    width: 8rem;
    top: 34%;
}
.dep__card-last10>.dep__card-monto-sc p {
    margin-left: calc(50% - 11rem);
    width: 8rem;
    margin-top: 0.7rem;
}
.dep__card-file{
    transition: all .2s ease;
}
.dep__card-file:hover{
    transform: scale(1.05);
    box-shadow:rgb(0, 0, 0);
    z-index: 20;
    cursor: pointer;
}
.dep__file-icon {
    position: absolute;
    width: 1.6rem;
    top: 24%;
    left: 35%;
    filter: invert(1);
}

.dep__file-icon-c {
    position: absolute;
    width: 1.6rem;
    top: 31%;
    left: 35%;
    filter: invert(1);
}
.dep__file-icon-c-text {
    position: static;
    margin-right: 5px;
    width: 1rem !important;
    filter: invert(1);
}
.dep__file-icon-text {
    position: static;
    margin-right: 5px;
    width: 1rem !important;
    filter: invert(1);
}

.dep__text {
    position: absolute;
    width: 80%;
    left: 15%;
    top: 20%;
    color: #ffffff;
    text-align: center;font-size: small;
}

.dep__card-empty {
    height: 3rem;
    padding: 0.5rem;
    width: 100%;
}
.dep__card-empty p{
    text-align: center;
}
.empty_dep_Button{
    width: 40%;
    position: absolute;
    height: 100%;
    right: 0;
    background: #babec2;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.titles{
    width: 25%;
}
.text-fac{
    color: #ffffff;
    font-size: 20px;
    padding: 2%;
}
.text-cp{
    margin-top: 2px;
    color: #ffffff;
    font-size: 20px;
    padding: 2%;
    
}
@media screen and (max-width: 1000px) {
    .dep-all-container {
        display: block;
    }
    .dep-type {
        width: 100%;
    }
}
@media screen and (max-width: 280px) {
.dep__card-today .dep__card-fecha p {
    font-size: .8rem;
    margin-left: 1rem;
    line-height: 1;
    margin-top: .6rem;
}
.dep__card-monto p {
    margin-left: 1rem;
}
.dep__card-last10>.dep__card-monto p {
    margin-left: .2rem;
    font-size: .85rem;
    top: 34%;
}
.dep__file-icon {
    left: 23%;
}
}
@media screen and (max-width: 490px) {
    .dep__card-last10>.dep__card-fecha{
        width: 20%;
        display: block;
        }
    .dep__card-last10>.dep__card-fecha p {
        font-size: .7rem;
        margin-left: 0;
    }
    .dep__card-last10>.dep__card-monto p {
        margin-left: 0;
        text-align: left;
    }
    .dep__card-last10>.dep__card-monto-sc p {
        margin-left: 0;
        margin: 1rem;
        text-align: left;
    }
    .dep__card-last10>.dep__card-xml {
        width: 20%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-xml-sc {
        width: 15%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-monto {
        width: 55%;
        right: 22%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-monto-sc {
        width: 55%;
        right: 22%;
        clip-path: none;
    }
    .dep__card-xml p{
        display: none;
    }
    .dep__card-last10>.dep__card-pdf {
        width: 15%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-pdf-sc{
        width: 15%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-pdf-text {
        width: 30%;
        clip-path: none;
    }
    .dep__card-last10>.dep__card-pdf-c {
        width: 13%;
        clip-path: none;
    }
    .depositos-container {
        width: 100%;
    }
    .dep__card {
        margin: .5rem 0.1rem;
    }
    .dep__file-icon {
        left: 24%;
    }
    .dep__file-icon-c {
        position: absolute;
        width: 1.6rem;
        top: 31%;
        left: 20%;
        filter: invert(1);
    }
    .dep__text {
        position: absolute;
        width: 80%;
        left: 10%;
        top: 15%;
        color: #ffffff;
        text-align: center;font-size: small;
    }
    .titles{
        width: 100%;
    }
}