#content_aviso_privacidad{
    padding: 3rem 15%;
}
#content_aviso_privacidad ol{
    padding: 1rem 5%;
}
#content_aviso_privacidad .paragraphs p{
    margin-top: 1rem;
}
#content_aviso_privacidad p, #content_aviso_privacidad li{
    color: rgb(104, 104, 104);
}
#content_aviso_privacidad h1{
    color: rgb(61, 61, 61);
    font-family: 'Montserrat', sans-serif;
    padding: 0.3rem 0;
    border-bottom: 1px solid gray;
    margin-bottom: 2rem;
}
#content_aviso_privacidad .firma {
    margin-top: 3rem;
}
#content_aviso_privacidad .firma p {
    display: grid;
}
#content_aviso_privacidad .firma p strong {
    margin-bottom: 0.3rem;
}