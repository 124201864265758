.card-wrapper {
    position: relative;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  .card-wrapper:active, .card-wrapper:hover, .card-wrapper:focus {
    outline: none;
    border: none;
  }
  
  .card {
    width: 100%;
    height: 450px;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    -webkit-box-shadow: 0px 0px 11px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 11px -5px rgba(0,0,0,0.75);
box-shadow: 0px 0px 11px -5px rgba(0,0,0,0.75);
  }
  
  .card .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000;
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .card .card-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .card:hover img {
    opacity: .4;
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .card:hover .card-image {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
    -webkit-transition: all .9s;
    transition: all .9s;
  }
  
  .card:hover li:nth-child(1) a {
    -webkit-transition-delay: .1s;
            transition-delay: .1s;
  }
  
  .card:hover li:nth-child(2) a {
    -webkit-transition-delay: .2s;
            transition-delay: .2s;
  }
  
  .card:hover li:nth-child(3) a {
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
  }
  
  .card:hover li:nth-child(4) a {
    -webkit-transition-delay: .4s;
            transition-delay: .4s;
  }
  
  .details {
    position: absolute;
    bottom: 400px;
    left: 0;
    background: #fff;
    width: 100%;
    height: 108px;
    z-index: 1;
    padding: 5px 10px;
    -webkit-transition: .6s;
    transition: .6s;
  }
  
  .details h2 {
    margin: 8% 0;
    padding: 0;
    text-align: center;
    font-size: .9rem;
  }
  
  .details h2 .job-title {
    font-size: .9rem;
    line-height: 1;
    color: #333;
    font-weight: 400;
    display: block;
    margin-top: .3rem;
    padding: 0 .5rem;
  }
  
  .card:hover .details {
    bottom: 0;
  }

  .card-title {
    display: block;
    z-index: 10;
    position: absolute;
    /* margin: 0 auto; */
    width: 100%;
    padding: .3rem 0;
    background: rgba(0,0,0,.2);
    background: linear-gradient(0deg, rgba(0,212,255,0) 0%, rgba(4,4,43,0.5) 90%, rgba(5,3,34,0.6) 100%);

}
.card-title h1 {
  text-align: center;
  color: #fff;
  margin-top: .4rem;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.maps-link{
  position: absolute;
  z-index: 5;
  background: white;
  padding: 0.4rem .6rem;
  border-radius: 100px;
  bottom: .7rem;
  right: .7rem;
  color: rgb(1, 55, 99);
  font-size: 1.3rem;
}

.card_active .card-image {
  transform: translateY(-80px) !important;
}
.card_active img {
  opacity: 1 !important;
}
.card_active .details {
  bottom: 0 !important;
  height: 95px !important;
  overflow: auto;
}
.card_active:hover{
  cursor:auto !important;
}

.slick-list{
  padding: .6rem 0 !important;
}
.buttonTrackingSucursal{
  width: 85%;
  height: 85%;
  position: absolute;
  left: 4px;
  top: 3px;
  opacity: 0;
}
@media screen and (max-width: 620px) {
  .card {
    height: 400px;
  } 
}
/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
  .card {
    height: 300px;
  } 
  .slick-prev, .slick-next {
    display: none !important;
}
}
