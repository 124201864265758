.resumenC__ {
    background: rgb(216, 220, 226);
    padding: 1rem;
    border-radius: 15px;
    margin: .4rem 0;
}
 .resumenC__datos.resumenC__generales {
    position: relative;
    background: #0f5faf;
    outline: .5rem solid #0f5faf;
}

.resumenC__datos.resumenC__generales::after {
    content: '';
    display: block;
    position: absolute;
    top: -16px;
    bottom: -16px;
    left: -16px;
    right: -16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 1rem solid #0f5faf;
}
.resumenC__datos h2{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    color : #e7821c
}
.resumenC__datos.resumenC__ultimo-deposito {
    margin-top: 1.6rem;
}
.resumenC__clave ul {
    list-style: none;
    display: block;
    margin: .6rem auto;
}
.resumenC__clave ul li{
    width: 2.3rem;
    padding: .5rem;
    margin: 0 .3rem;
    border-radius: 60px;
    background-color: #347ddd;
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background-image: linear-gradient(147deg, #347ddd 0%, #4b6888 74%);
}
.vencida li{
    width: auto !important;
    padding: .3rem 1rem !important;
    color: white !important;
    background-image: linear-gradient(
147deg, rgb(240, 164, 50)0%, rgb(233, 137, 27) 74%) !important;
}
.sinClave li{
    width: auto !important;
    padding: .3rem 1rem !important;
    color: rgb(233, 137, 27) !important;
    background-image: linear-gradient(
147deg, rgb(255, 255, 255)0%,rgb(250, 248, 245) 74%) !important;
}
.resumenC__clave ul li.clave-big{
    width: 6.5rem;
    padding: .5rem;
    margin: 0 auto;
    border-radius: 15px;
    font-weight: 400;
    background-color: #eb8633;
    background-image: linear-gradient(147deg, #ebaa33 0%, #eb8633 74%);
    -webkit-box-shadow: 0px 0px 25px -15px rgba(74,74,74,1);
-moz-box-shadow: 0px 0px 25px -15px rgba(74,74,74,1);
box-shadow: 0px 0px 25px -15px rgba(74,74,74,1);
}
.resumenC__clave-num {
    display: flex;
    margin: .6rem auto;
    justify-content: center;
}
.resumenC__generales p{
    color: #4eccfa;
    text-shadow: 1px 1px 4px rgba(0,0,0,.6);
}
.resumenC__generales p span{
    color: rgb(236, 236, 236);
    text-shadow: 1px 1px 4px rgba(0,0,0,.3);
}
.resumenC__ultimo-deposito p{
    color: #347ddd;
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.6);
}
.resumenC__ultimo-deposito p span{
    color: #444444;
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.3);
}
#entregar_clave:hover{
    cursor: pointer;
}