/**SLIDER***************************************************/
.MuiSlider-root {
    color: #054683 !important;
}
.sliderInputForm>.MuiSlider-track {
    height: 6px;
    border-radius: 5rem;
    color: #005a96;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.sliderInputForm>.MuiSlider-rail {
    border-radius: 5rem;
    height: 6px;
    color: #118adb;
}
.sliderInputForm>.MuiSlider-thumb {
    width: 18px;
    height: 18px;
    margin-top: -7px;
    background-color: #005a96;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiSlider-valueLabel
{
    left: calc(50% - 1.8rem) !important;
}
.sliderBar-width{
    width: 68% !important;
}
.sliderText-width{
    width: 32% !important;
}
.sliderText-width input{
    font-size: .9rem !important;
    text-align: center;
    padding-left: 0;
}
.cotizador_info {
    margin: 0.5rem 0;
    position: relative;
}
.cotizador_info span {
    font-family: 'Montserrat';
    font-size: .8rem;
    color: #005a96;
    /* margin: -0.7rem; */
}
.PrivateValueLabel-circle-4 {
    width: 59px !important;
    transform: rotate(0deg) !important;
    border-radius: 14px !important;
}
.PrivateValueLabel-label-5 {
    transform: rotate(0deg) !important;
}
.slider-format-errors {
    width: 95%;
    margin-top: -1rem;
}
.slider-format-errors p {
    font-size: .8rem;
    line-height: 14px;
    color: red;
}
span.MuiSlider-markLabel.MuiSlider-markLabelActive, span.MuiSlider-markLabel {
    color: gray !important;
    font-size: .7rem !important;
    font-family: 'Montserrat', sans-serif !important;
    margin-left: 1.3rem !important;
}
.MuiSlider-markLabel:nth-last-of-type(2){
    left: 80% !important;
}
.cotizador_info span{
    display: block;
    margin-left: .7rem;
}
.cotizador_info span:before {
    content: '*';
    position: absolute;
    left: 2px;
    top: 2px;
}
/*CELULAR*******************************************/
@media screen and (max-width: 565px) {
    .sliderBar-width {
        width: 100%!important;
    }
    .sliderText-width {
        width: 100%!important;
    }
}