/*RADIO SELECT*******/
@media screen and (max-width: 390px) {
  #div_ficha_deposito{
      padding: 1em .5em !important;
  }
}
  .container-forma-pago {
    margin: 1rem auto 1rem auto;
    display: flex;
    width: 100%;
    place-content: space-evenly;
  }
  .option {
    width: 45%;
}
.selecTipoPago_label {
    width: 100%;
    padding: 0.3rem 0.4rem;
    align-self: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: left;
    color: rgb(70, 70, 70);
}
  [type="radio"] {
  z-index: -1;
  position: absolute;
  opacity: 0;
  }
  [type="radio"]:checked{
  border-color: #3a75e4;
  background-color: rgba(97, 154, 234, 0.16);
  color: #3a75e4;
  }
  [type="radio"]:checked:before {
  will-change: transform, border-width, border-color;
  -webkit-animation: bubble 1s ease-in;
          animation: bubble 1s ease-in;
  }
  [type="radio"]:checked:after {
  will-change: opacity, box-shadow;
  -webkit-animation: sparkles 700ms ease-in-out;
          animation: sparkles 700ms ease-in-out;
  }
  [type="radio"]:checked ~ span {
  will-change: transform;
  border: 0;
  background-image: linear-gradient(to top right, #6E89FF, #4363EE);
  -webkit-animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
          animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
  }
  [type="radio"]:checked ~ span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #fff;
  }
  [type="radio"]:checked ~ .label-pago .card {
  will-change: box-shadow;
  -webkit-animation: card 500ms ease-in-out forwards;
          animation: card 500ms ease-in-out forwards;
  }
  [type="radio"]:checked ~ .label-pago .card:after {
  will-change: transform;
  -webkit-animation: shine 500ms ease-in forwards;
          animation: shine 500ms ease-in forwards;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
  }
  
  .label-pago {
  position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 20px auto 10px;
    grid-gap: 20px;
    width: 420px;
    max-width: 100%;
    height: 62px;
    min-height: 62px;
    max-height: 83px;
    padding: 0 20px;
    border-radius: 10px;
    border: 2px solid transparent;
    background-color: transparent;
    transition: all 300ms ease-in;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  }
  .label-pago:hover {
  border-color: #3a75e4;
  background-color: rgba(97, 154, 234, 0.16);
  }
  .label-pago:before, .label-pago:after {
  position: absolute;
  left: 29px;
  border-radius: 50%;
  content: '';
  }
  .label-pago:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
    box-shadow: 0.32476rem -2.6875rem 0 -0.1875rem #80e8ff, -0.32476rem -2.3125rem 0 -0.1875rem #80b7ff, 2.30366rem -1.42172rem 0 -0.1875rem #0a3694, 1.6055rem -1.69573rem 0 -0.1875rem #144f9c, 2.54785rem 0.91464rem 0 -0.1875rem #144f9c, 2.32679rem 0.19796rem 0 -0.1875rem #80ffc8, 0.87346rem 2.56226rem 0 -0.1875rem #80ffc8, 1.29595rem 1.94258rem 0 -0.1875rem #80c8ff, -1.45866rem 2.28045rem 0 -0.1875rem #80c8ff, -0.71076rem 2.2244rem 0 -0.1875rem #a480ff, -2.69238rem 0.28141rem 0 -0.1875rem #a480ff, -2.18226rem 0.8312rem 0 -0.1875rem #0b78b8, -1.89869rem -1.92954rem 0 -0.1875rem #1a7c91, -2.01047rem -1.18791rem 0 -0.1875rem #2c8ea3;
  }
  .label-pago > span {
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #2755a3;
  }
  .text__row {
  display: grid;
  grid-template-columns: 54px 64px;
  grid-gap: 6px;
  }
  .text__row:last-of-type {
  grid-template-columns: 45px 54px;
  margin-top: 7px;
  }
  .text__loader {
  height: 13px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  }
  
  .option:not(:last-child) {
  margin-bottom: 4px;
  }
  
  @-webkit-keyframes radio {
  0%, 17.5% {
    transform: scale(0);
  }
  }
  
  @keyframes radio {
  0%, 17.5% {
    transform: scale(0);
  }
  }
  @-webkit-keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #545461;
    border-width: 2rem;
  }
  30%, 100% {
    transform: scale(1);
    border-color: #545461;
    border-width: 0;
  }
  }
  @keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #545461;
    border-width: 2rem;
  }
  30%, 100% {
    transform: scale(1);
    border-color: #545461;
    border-width: 0;
  }
  }
  @-webkit-keyframes sparkles {
  0%, 10% {
    opacity: 0;
    transform: scale(0);
  }
  15% {
    opacity: 1;
    transform: scale(1.2) rotate(-20deg);
    box-shadow: 0.32476rem -2.1875rem 0 0rem #80e8ff, -0.32476rem -1.8125rem 0 0rem #80b7ff, 1.91274rem -1.10998rem 0 0rem #80ccff, 1.21459rem -1.38398rem 0 0rem #9780ff, 2.06039rem 0.80338rem 0 0rem #9180ff, 1.83932rem 0.0867rem 0 0rem #80dbff, 0.65652rem 2.11178rem 0 0rem #80ecff, 1.07901rem 1.4921rem 0 0rem #80c8ff, -1.24172rem 1.82996rem 0 0rem #80c8ff, -0.49382rem 1.77391rem 0 0rem #8b80ff, -2.20492rem 0.17015rem 0 0rem #836aeb, -1.69479rem 0.71994rem 0 0rem #80dfff, -1.50777rem -1.61779rem 0 0rem #8d80ff, -1.61955rem -0.87617rem 0 0rem #80c2ff;  }
  }
  @keyframes sparkles {
  0%, 10% {
    opacity: 0;
    transform: scale(0);
  }
  15% {
    opacity: 1;
    transform: scale(1.2) rotate(-20deg);
    box-shadow: 0.32476rem -2.1875rem 0 0rem #80e8ff, -0.32476rem -1.8125rem 0 0rem #80b7ff, 1.91274rem -1.10998rem 0 0rem #80ccff, 1.21459rem -1.38398rem 0 0rem #9780ff, 2.06039rem 0.80338rem 0 0rem #9180ff, 1.83932rem 0.0867rem 0 0rem #80dbff, 0.65652rem 2.11178rem 0 0rem #80ecff, 1.07901rem 1.4921rem 0 0rem #80c8ff, -1.24172rem 1.82996rem 0 0rem #80c8ff, -0.49382rem 1.77391rem 0 0rem #8b80ff, -2.20492rem 0.17015rem 0 0rem #836aeb, -1.69479rem 0.71994rem 0 0rem #80dfff, -1.50777rem -1.61779rem 0 0rem #8d80ff, -1.61955rem -0.87617rem 0 0rem #80c2ff;  }
  }
    /*MOBILE CEL*******************************************/
    @media screen and (max-width: 520px) {
      .container-forma-pago {
        display: block;
      }
      .option{
        width: 95%;
      }
      .ficha-deposito {
        display: none;  
      }
      .efectivo-content {
        width: 100%;
      }
      .selectFLugar__combo {
        margin-top: .5rem;
      }
    }