.tel_unidad {
    display: flex;
    min-height: 3rem;
    max-height: 5rem;
    margin: 0.3rem 0;
    border-bottom: 1px solid #0a9de0;
}
.tel_unidad:last-of-type{
    border-bottom: none;
    margin-bottom: -0.8rem;
}
.tel__abierto {
    color: rgb(51, 155, 51);
}
.tel__cerrado {
    color: rgb(204, 58, 47);
}
.tel__por {
    color: rgb(240, 164, 50);
}
.tel__cont {
    width: 85%;
    text-align: left;
}
.tel__cont h2 {
    margin: 0;
    margin-top: 0.2rem;
    font-size: .9rem;
    color: #0a9de0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.tel__cont p {
    margin: 0;
    font-size: .8rem;
}
.tel__icon {
    display: inline-flex;
    width: 15%;
    justify-content: right;
}
.tel__tel-icon {
    margin: 0;
    filter: invert(1) brightness(.5) sepia(100) saturate(10) hue-rotate(190deg) saturate(2.5) brightness(0.8);
}
.dm-icon {
    width: 1.5rem;
}
.tel__tel-icon:hover {
    cursor: pointer;
    filter: invert(1) brightness(.5) sepia(100) saturate(10) hue-rotate(190deg) saturate(2.5) brightness(0.6);
}
.tel__cont p span {
    color: rgb(65, 65, 65);
    display: block;
    margin-top: -0.2rem;
    margin-bottom: 0.4rem;
}