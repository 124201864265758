.card_Contrato {
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 32%;
    min-width: 0;
    height: 180px;
    margin: .3rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    padding: 0.4rem 0.8rem;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 30px -23px rgb(26 26 26);
    -moz-box-shadow: 0px 0px 30px -23px rgba(26,26,26,1);
    box-shadow: 0px 0px 30px -23px rgb(26 26 26);
}
.card_Contrato-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
}
.card_Contrato-content {
    display: block;
}
.selectC {
    padding: 0.6rem 0;
    width: 95%;
}
/* .selectC__ref {
    width: 100%;
}
.selectC__datos {
    width: 100%;
} */
.selectC.selectC__datos {
    white-space: normal;
}
.selectC__ref p {
    color: rgb(26, 58, 116);
    font-size: 1rem;
    font-weight: 700;
}
.selectC__ref h3 {
    color: rgb(26, 58, 116);
    margin-top: -0.3rem;
}
.selectC__ref h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.45rem;
    font-weight: 800;
    /* font-size: 72px; */
    background: -webkit-linear-gradient(#2d6192, rgb(62, 112, 187));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.selectC__datos h3 {
    margin-bottom: 0.3rem;
    font-size: .9rem;
    font-weight: 500;
}
.deleteC {
    position: absolute;
    top: -0.3rem;
    right: -0.25rem;
    border: none;
    background: none;
    width: 17px;
    height: 17px;
    z-index: 2;
}
.deleteC_svg {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    border: none;
    background: none;
    font-size: 1.5rem;
    color: rgb(145, 145, 145);
    transition: all .1s ease;
    z-index: 1;
}
.deleteC:hover + .deleteC_svg {
    cursor: pointer;
    color: rgb(218, 127, 52);
}
/*ANIMACIONES*********************************************/
.card_Contrato{
    opacity:0;
   -moz-animation: popin .4s ease .8s forwards;
   -webkit-animation: popin .4s ease .8s forwards;
   -o-animation: popin .4s ease .8s forwards;
   -ms-animation: popin .4s ease .8s forwards;
    animation: popin .4s ease .8s forwards;
}
@-moz-keyframes popin /* Firefox */{
    0% {opacity: 0; -moz-transform: scale(0.5);}
    40% {opacity: 1; -moz-transform: scale(1);}
    70%  {transform: scale(0.9);}
    /* 85%  {transform: scale(1.1);} */
    100% {opacity: 1; transform: scale(1);}
}

@-webkit-keyframes popin /* Safari and Chrome */{
    0% {opacity: 0; -webkit-transform: scale(0.5); }
    40% {opacity: 1; -webkit-transform: scale(1);}
    77%  {-webkit-transform: scale(0.9);}
    /* 85%  {-webkit-transform: scale(1.1);} */
    100% {opacity: 1; -webkit-transform: scale(1);}
}

@-o-keyframes popin /* Opera */{
    0% {opacity: 0; transform: scale(0.5); }
    40% {opacity: 1; transform: scale(1);}
    70%  {transform: scale(0.9);}
    /* 85%  {transform: scale(1.1);} */
    100% {opacity: 1; transform: scale(1);}
}

@-ms-keyframes popin /* IE10 */{
    0% {opacity: 0; transform: scale(0.5); }
    40% {opacity: 1; transform: scale(1);}
    70%  {transform: scale(0.9);}
    /* 85%  {transform: scale(1.1);} */
    100% {opacity: 1; transform: scale(1);}
}

@keyframes popin{
    0% {opacity: 0; transform: scale(0.5); }
    40% {opacity: 1; transform: scale(1);}
    70%  {transform: scale(0.9);}
    /* 85%  {transform: scale(1.1);} */
    100% {opacity: 1; transform: scale(1);}
}

.card_Contrato_hide{
    opacity:0;
    /* overflow: hidden; */
   -moz-animation: popout .4s ease .8s backwards;
   -webkit-animation: popout .4s ease .8s backwards;
   -o-animation: popout .4s ease .8s backwards;
   -ms-animation: popout .4s ease .8s backwards;
    animation: popout .4s ease .8s backwards;
}

@-moz-keyframes popout /* Firefox */{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; -moz-transform: scale(1);}
    100% {opacity: 0; -moz-transform: scale(0.5);}
  
}

@-webkit-keyframes popout /* Safari and Chrome */{
    0% {opacity: 1; -webkit-transform: scale(1);}
    40%  {-webkit-transform: scale(0.9);}
    70% {opacity: 1; -webkit-transform: scale(1);}
    100% {opacity: 0; -webkit-transform: scale(0.5); }
}

@-o-keyframes popout /* Opera */{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); }  
}

@-ms-keyframes popout /* IE10 */{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); }  
}

@keyframes popout{
    0% {opacity: 1; transform: scale(1);}
    40%  {transform: scale(0.9);}
    70% {opacity: 1; transform: scale(1);}
    100% {opacity: 0; transform: scale(0.5); } 
}

@media screen and (max-width: 1050px) {
    .card_Contrato {
        width: 45%;
    }
}
/*MOBILE Tablet*******************************************/

@media screen and (max-width: 850px) {
    .card_Contrato {
        height: 175px;
    }
}
/*MOBILE CEL*******************************************/
@media screen and (max-width: 520px){
    .card_Contrato {
        width: 95%;
        height: 160px;
    }
}