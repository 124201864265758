.selectC__ {
    display: flex;
    height: 3rem;
    padding: .3rem .4rem;
    flex: 2;
    flex-grow: 0;
  }
  .selectC__label {
    width: 58%;
    align-self: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .selectC__combo {
    width: 42%;
    align-self: center;
    height: 100%;
  }
  .selectC__combo select {
    outline: none;
    border: none;
    padding: 0 .3em 0 .3rem;
    margin: 0;
    line-height: inherit;
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgb(216, 220, 226);
    border-radius: 10px;
    color: #0f5faf;
    border-right: 0.5rem solid rgb(216, 220, 226);
  }
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 810px) {
    .selectC__ {
        display: block;
        height: auto;
    }
    .selectC__label {
        width: 100%;
      }
      .selectC__combo {
        width: 100%;
      }
}
  /*MOBILE CEL*******************************************/
  @media screen and (max-width: 520px) {
    
  }

/*ESTILOS NUEVOS PARA TARJETAS*********************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
.selectC_title {
  padding: 0.3rem;
}
.selectC_title h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  color: var(--dark_blue);
}
.selectC__cards {
  display: block;
  height: auto;
  max-height: 50%;
  padding: .2rem .6rem .2rem .5rem;
  flex: 2;
  flex-grow: 0;
  overflow: auto;
  background: rgb(212, 216, 221);
  border-radius: 10px;
}
/* Works on Firefox */
.selectC__cards {
  scrollbar-width: thin;
  scrollbar-color: blue white;
}

/* Works on Chrome, Edge, and Safari */
.selectC__cards::-webkit-scrollbar {
  width: 12px;
}

.selectC__cards::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.selectC__cards::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  border: 3px solid rgb(212, 216, 221);
}
.selectcard_Contrato {
  position: relative;
  display: block;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  height: 120px;
  max-height: 135px;
  margin: 0.45rem 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  box-shadow: 0px 0px 30px -23px rgb(26 26 26);
}
.selectcard_Contrato:hover{
  cursor: pointer;
  padding: calc(0.4rem - 2px) calc(0.8rem - 2px);
  border: 2px solid var(--light-blue);
}
.selectcard_Contrato-body {
  display: flex;
  height: 100%;
  align-items: center;
}
.selectcard_Contrato__ref {
  display: inline;
  align-items: flex-end;
  justify-content: flex-end;
  /* height: 30%; */
  width: 35%;
  margin: 0 auto;
}
.selectcard_Contrato__datos {
  width: 65%;
  display: grid;
  /* height: 70%; */
  margin: 0 auto;
}
.selectcard_Contrato__datos h3 {
  font-size: .9rem;
  font-weight: 500;
  text-align: right;
  /* align-self: center; */
}
.selectcard_Contrato__datos h3:first-of-type {
  align-self: flex-end;
  font-weight: 600;
  color: var(--blue);
}
.selectcard_Contrato__datos h3:last-of-type {
  align-self: flex-start;
  font-size: .85rem;
}
.selectcard_Contrato__ref p {
  color: rgb(26, 58, 116);
  font-size: .9rem;
  font-weight: 700;
  margin-bottom: .1rem;
  margin-right: .5rem;
}
.selectcard_Contrato__ref h3{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  /* font-size: 72px; */
  background: -webkit-linear-gradient(#2d6192, rgb(62, 112, 187));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.selectcard_Contrato_selected{
  padding: calc(0.4rem - 2px) calc(0.8rem - 2px);
  background: var(--blue-test);
  border: 2px solid var(--blue);
}
span.activeCard {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  background: var(--blue);
  border-radius: 50%;
  top: 0.4rem;
  left: 0.5rem;
}
.empty_selectcard_Contrato {
  padding: 0.4rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: var(--light_dark_blue);
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 1400px) {
  .selectcard_Contrato__ref p {
    font-size: .7rem;
  }
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 910px) {
    .selectC__ {
        display: block;
        height: auto;
    }
    .selectcard_Contrato__ref p {
      font-size: .65rem;
    }
    .selectcard_Contrato__ref h3{
      font-size: 1.3rem;
    }
    .selectcard_Contrato__datos h3 {
      font-size: .85rem;
    }
    .selectcard_Contrato__datos h3:last-of-type {
      font-size: .8rem;
    }
    .selectcard_Contrato {
      height: 110px;
      max-height: 120px;
    }
}
  /*MOBILE CEL*******************************************/
  @media screen and (max-width: 520px) {
    .selectcard_Contrato__ref p {
      font-size: .6rem;
    }
    .selectcard_Contrato__ref h3{
      font-size: 1.1rem;
    }
    .selectcard_Contrato__datos h3 {
      font-size: .74rem;
    }
    .selectcard_Contrato__datos h3:last-of-type {
      font-size: .7rem;
    }
    .selectcard_Contrato {
      height: 100px;
      max-height: 110px;
    }
  }