.home__hero-section{
    color: #fff;
    padding: 3.5rem 9.5%;
}

.home__hero-row{
    align-items: center;
}
.home__hero-container {
    z-index: 1;
    width: 95%;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    height: 100%;
}
.row{
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
    height: 100%;
}

.col{
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: initial;
    flex-basis: 50%;
}

.home__hero-text-wrapper{
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 5rem;
}

.top-line{
    color: #07aae6;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading{
    margin-bottom: 24px;
    font-size: 46px;
    line-height: 1.1;
    font-weight: 700;
    color: #f7f8fa;
    font-family: 'Montserrat', sans-serif;
}

.dark{
    color: #1c2737;
}

.darkBg{
    background-color: #1c2737;
}

.home__hero-list{
    max-width: 440px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
}
.home__hero-paragraph{
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
}
.home__hero-img-wrapper{
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
}

.home__hero-img{
    max-width: 98%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
    margin: 0 0 0 10px;
}
.home__hero-img svg{
    width: 100%;
    height: 100%;
}
img{
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.home__Hero-text-wraper ul{
    margin-left: 2rem;
}
.home__Hero-text-wraper ul li{
    font-size: 1.2rem;
}
.heroMoreButtons {
    display: flex;
}
.heroMoreButtons button{
    margin: .8rem;
    max-width: 15rem;
    padding: 1rem;
    white-space: inherit;
    height: calc(100% - 1.6rem);
}
.textCenter .top-line, .textCenter h1, .textCenter p, .textCenter {
    text-align: center;
    text-align: -webkit-center;
}
.textRight .top-line, .textRight h1, .textRight p, .textRight {
    text-align: right;
    text-align: -webkit-right;
}
.textRight p, .textLeft p{
    font-size: 1.1rem;
}
.textLeft .top-line, .textLeft h1, .textLeft p, .textLeft {
    text-align: left;
    text-align: -webkit-left;
}
.hero-app-btn {
    width: 11rem;
    margin: .1rem 1rem .1rem 0;
}
@media screen and (max-width: 768px){
    .home__herotext-wrapper {
        padding-bottom: 65px;
    }
    .col{
        max-width: 100%;
        flex-basis: 100%;
    }
    .heroMoreButtons {
        display: flex;
        flex-direction: column;
        text-align: -webkit-center;
        align-items: center;
        place-items: center;
    }
    .heroMoreButtons .btn, .heroMoreButtons a {
        width: 100%;
    }
}
/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
    .heading {
        font-size: 2rem;
    }
    .home__hero-section {
        padding: 4rem 2%;
    }
    .home__hero-container {
        width: 100%;
        padding: 1rem ;
    }
  }