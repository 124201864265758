.contacto_oficinas{
    width: 100%;
    padding: 2rem;
    background: white;
    box-shadow: 0px 0px 16px -8px rgb(0 0 0 / 75%);
    margin: 1rem 0;
    border-radius: 20px;
}
.oficina{
    margin: 1rem .3rem;
}
.oficina h1{
    font-size: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    color: #07aae6;
}
.oficina p{
    font-size: 1.05rem;
    margin: .1rem 0;
    color: rgba(0,0,0,.8);
}