.option-content.contact-content {
    display: inline-block;
    width: 100%;
}
.mail-wrapper {
    margin-bottom: 1.5rem;
}
.contact-content .button-opciones-cuenta {
    border: 2px solid #0892ee;
}
.contact-content svg{
    color: #0892ee;
}
.contact-content p:hover{
    color: #0892ee;
}
/*TABLET ****/
@media screen and (max-width: 890px) {
    .contact-content .button-opciones-cuenta {
        width: 94%;
    }
}
/*CELULAR ****/
@media screen and (max-width: 490px) {
    .contact-content .button-opciones-cuenta {
        width: 94%;
    }
}
