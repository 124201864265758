.contact-section {
    background-color: #f3f3f3;
    padding: 2rem 14rem;
    display: flex;
    flex-basis: 50%;
}
.c-section {
    width: 50%;
    margin: 1rem;
    display: flex;
    flex-flow: column;
}
.mensaje-section{
    width: 60%;
}
.contact-info-section{
    width: 40%;
}
.c-section h3{
    font-family: 'Montserrat';
    font-size: 3rem;
    line-height: 1;
    width: 65%;
}
/**Tablet*********************************************************/
@media screen and (max-width: 850px) {
    .contact-section {
        padding: 2rem 2rem;
        display: block;
    }
    .c-section{
        width: 95%;
        margin: 0 auto;
    }
  }
/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
    .ContactForm, .contacto_oficinas {
        padding: 1rem !important;
    }
    .contact-section {
        padding: 2rem 1rem;
    }
    .c-section h3{
        font-size: 2.3rem;
    }
  }