.slider {
    position: relative;
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #01468b;
}
  
  .image {
    width: 100%;
    /* height: 600px; */
    object-fit: cover;
    /* border-radius: 10px; */
}
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 2rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: .6;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 2rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: .6;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease-in-out;
    position: relative;
  }
  
  .slide.active {
    width: 100%;
    background-size: cover;
    background-position: center center;
    height: 100%;
    /* width: 100vw; */
    opacity: 1;
    transition-duration: 1s;
    /* transform: scale(1.08); */
    display: block;
}
.is{
  display: none;
  position: absolute;
  padding: 4.3rem;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 80px;
}
.active>.is{
  display: block;
}
.active>.isf_v{
  padding: 10rem 8rem;
}
.active>.isf_s{
  padding: 8rem;
}
.active>.isf_h{
  padding: 10rem 13rem;
}
.isp_bottom{
  bottom: 7%;  
}
.isp_middle{
  top: 35%;
}
.isp_top{
 top: 7%;
}
.isp_center{
  right: 35%;
}
.isp_left{
  left: 7%;
}
.isp_right{
  right: 7%;
}
.is a {
  width: auto;
  padding: .35rem;
  background: transparent;
  height: auto;
  display: block;
  position: absolute;
}
.is a.bottom40{
  bottom: 40%;
}
.is a.bottom30{
  bottom: 30%;
}
.is a.bottom15{
  bottom: 15%;
}
.is a.bottom05{
  bottom: 5%;
}
.is a.left{
  left: 1%;
}
.is a.right{
  right: 1%;
}
.is a button{
  border-radius: 7px;
  padding: 0.8rem 1.2rem;
  outline: 0;
  border: 0;
  color: white;
  background: var(--blue);
  box-shadow: 0px 0px 11px -5px rgb(0 0 0 / 75%);
}
@media screen and (max-width: 620px) {
  .active>.isf_v{
    padding: 7.5rem 6rem;
  }
  .active>.isf_s{
    padding: 6rem;
  }
  .active>.isf_h{
    padding: 6rem 7.5rem;
  }
  .is a button{
    font-size: 1rem;
    padding: 0.7rem 1.1rem;
  }
}
@media screen and (max-width: 420px) {
  .slider {
    height: 18rem;
  }
  .left-arrow {
    left: 15px;
    font-size: 1.2rem;
  }
  .right-arrow {
    right: 15px;
    font-size: 1.2rem;
  }
  .active>.isf_v{
    padding: 5rem 4rem;
  }
  .active>.isf_s{
    padding: 4.3rem;
  }
  .active>.isf_h{
    padding: 4rem 4.75rem;
  }
  .is a button{
    font-size: .9rem;
    padding: 0.6rem 1rem;
  }
  .is a.bottom05 {
    bottom: -5%;
  }
  .is a.bottom30 {
    bottom: 20%;
  }
  .is a.bottom15 {
    bottom: 10%;
  }
  .is a.bottom40{
    bottom: 30%;
  }
  .is a.left{
    left: -3%;
  }
  .is a.right{
    right: -3%;
  }
  .isp_top {
    top: -3%;
  }
  /*FIX CELLPHONE VIEW OF FIRST HERO**/
  .slide:first-of-type .is a{
    margin-bottom: -3.5rem;
  }
}