.ir__content {
  background: #111b25;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  padding: 0;
  margin: 0;
  height: inherit;
}

.h h1 {
  font-family: "Montserrat";
  font-size: 2rem;
  margin-top: -.5rem;
  white-space: pre-wrap;
}

.h p {
    font-size: 1rem;
    margin: .5rem 5%;
    white-space: pre-wrap;
}

.w {
  margin: 0 auto;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* background: rgba(66, 66, 66, 0.5) url("https://unsplash.imgix.net/reserve/PPE2xapKRNyy2DlTt89F_Gutman_island.jpg?fit=crop&fm=jpg&h=1500&q=75&w=2400") repeat fixed; */
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: 50% 100%;
  background-size: cover;
  position: relative;
  z-index: 5;
  font-size: 0;
}

.i {
  width: 40px;
  height: 100%;
  display: inline-block;
  position: relative;
  z-index: 4;
  padding: 2px;
  transition: all 1.3s ease-in-out;
  /* background: rgba(66, 66, 66, 0.5) url("http://unsplash.imgix.net/reserve/de9uL9L7RSmzV4SAoAO5_Lauren%20and%20Winona%20Under%20a%20pass-1.jpg?fit=crop&fm=jpg&h=1500&q=75&w=2400") repeat fixed; */
  background: rgba(66, 66, 66, 0.5) repeat fixed;
  background-size: cover;
  background-position: 50% 100%;
  border-radius: 0%;
}
.i:hover {
  transition: all 0s linear;
  opacity: 0;
}

.h {
  display: block;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  padding: 0 10%;
  top: 35px;
  font-size: 25px;
  color: beige;
  text-shadow: 0 5px 17px rgba(87, 87, 80, 0.85);
}
/**mobile**********************************************************/
@media screen and (max-width: 760px) {
  .mobile_1{
      width: 100% !important;
      height: 13rem !important;
  }
}
@media screen and (max-width: 580px) {
  .mobile_0{
      display: none !important;
      width: 0% !important;
  }
  .mobile_1{
      width: 100% !important;
      height: 18rem !important;
  }
  .mobile_2{
      width: 50% !important;
  }
}