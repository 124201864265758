.button-opciones-cuenta {
    transition: all .1s ease;
    background: white;
    margin: 1rem;
    padding: 0 1rem;
    border-radius: 13px;
    box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 30%);
    max-width: 1300px;
    width: calc(50% - 4rem);
    float: left;
}
.button-opciones-cuenta:hover{
    -webkit-box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -10px rgb(0 0 0 / 75%);
}
.opciones-cuenta-header:hover{
    cursor: pointer;
}
.opciones-cuenta-header:hover > p{
    color: var(--blue); 
}
.opciones-cuenta-header {
    display: flex;
    height: 90px;
}
.opciones-cuenta-content {
    padding: 0.3rem 0.5rem 1.5rem 0.5rem;
    opacity: 1;
    /* 
    padding: 0; 
    height: 0; 
    visibility: hidden; */
}
.groupCuenta {
    border: 1px solid rgba(63, 95, 143, 0.2);
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    background: rgba(63, 95, 143, 0.1);
}

.groupCuenta_prueba {
    border: 1px solid rgba(63, 95, 143, 0.2);
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    background: rgba(63, 95, 143, 0.1);
    height: 80px;
}

.opciones-cuenta-links{
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.opciones-cuenta-img {
    position: relative;
    width: 70px;
}
.opciones-cuenta-circle {
    width: 3.5rem;
    max-height: 3.5rem;
    height: -webkit-fill-available;
    border-radius: 15px;
    margin: 0.65rem;
    position: absolute;
    top: calc(50% - 2.55rem);
    left: calc(50% - 2.3rem);
    z-index: 0;
}
.opciones-cuenta-circle h1 {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: .75rem;
    text-align: center;
    display: block;
    top: 35%;
    position: absolute;
    margin: 0;
    width: 100%;
}
.opcion-conf-icon {
    color: var(--blue);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    display: block;
    top: calc(50% - 1rem);
    position: absolute;
    margin: 0;
    width: 100%;
}
/*MOBILE Tablet*******************************************/
@media screen and (max-width: 810px) {
    .button-opciones-cuenta {
        width: 95%;
        float: none;
    }
    
}
/*MOBILE CEL*******************************************/
@media screen and (max-width: 520px) {
    
}