@font-face {
  font-family: 'Pacifico';
  font-display: swap;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
:root{
  --light_sky: rgb(125, 160, 189);
  --light_dark_blue: rgb(86, 103, 128);
  --light_blue: rgb(112, 138, 163);
  --dark_blue: rgb(25, 49, 65);
}
button, input{
  outline: none;
}
button{
  display: block;
}
button:hover{
  cursor: pointer;
}
.mapSection {
  border-top: 1px solid rgb(172, 172, 172);
}
.slick-slide[aria-hidden=true] {
  visibility: hidden;
}
.referenceDivTag{
  display: none;
}
.line-break{
  height: 1.3rem;
}
/*BOCL ALERT******************/
.boclAlertWrapper {
  display: block;
  position: fixed;
  z-index: 1000000;
  top: 10%;
  left: calc(50% - 10rem);
  width: 22rem;
}
.boclAlertBox {
  background: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 28px -15px rgb(0,0,0);
}
.boclAlertBox h1{
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
}
.boclAlertBox p{
  font-size: 1rem;
}
.boclAlertBox h3{
  color: rgb(85, 85, 85);
}
.boclAlertContent {
  padding: 0.5rem 1rem 1rem 1rem;
}
.boclAlertSimpleContent {
  margin: 0.2rem 1rem;
}
.boclAlertFormContent {
  margin: 1rem;
  margin-top: 0;
}
.boclAlertButtonsDiv {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem;
}
.boclAlertButtonsDiv button {
  outline: none;
  min-width: 4rem;
  border: none;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  color: rgba(29, 175, 29, 0.918);
}
.boclAlertButtonsDiv button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.boclAlertButtonsDiv #boclCancelButton {
  background: rgb(121, 121, 121);
  color: white;
  transition: all .1s ease;
}
.boclAlertButtonsDiv #boclCancelButton:hover {
  background: rgb(97, 97, 97);
}

.boclAlertSuccess{
  background-color: rgb(238, 255, 238);
  border: 2px solid rgb(26, 168, 26);
}
.boclAlertSuccess h1 {
  color: rgb(26, 168, 26);
}
.boclAlertSuccess #boclConfirmButton {
  background: rgb(26, 168, 26);
  color: white;
  transition: all .1s ease;
}
.boclAlertSuccess #boclConfirmButton:hover {
  background: rgb(27, 114, 27);
}

.boclAlertInfo{
  background-color: rgb(240, 251, 255);
  border: 2px solid rgb(11, 143, 204);
}
.boclAlertInfo h1{
  color: rgb(11, 143, 204);
}
.boclAlertInfo #boclConfirmButton {
  background: rgb(11, 143, 204);
  color: white;
  transition: all .1s ease;
}
.boclAlertInfo #boclConfirmButton:hover {
  background: rgb(32, 99, 131);
}

.boclAlertError{
  background-color: rgb(255, 240, 240);
  border: 2px solid rgb(202, 35, 35);
}
.boclAlertError h1{
  color: rgb(202, 35, 35);
}
.boclAlertError #boclConfirmButton {
  background: rgb(202, 35, 35);
  color: white;
  transition: all .1s ease;
}
.boclAlertError #boclConfirmButton:hover {
  background: rgb(155, 34, 34);
}

.boclAlertWarning{
  background-color: rgb(253, 255, 238);
  border: 2px solid rgb(219, 188, 13);
}
.boclAlertWarning h1{
  color: rgb(219, 188, 13);
}
.boclAlertWarning #boclConfirmButton {
  background: rgb(219, 188, 13);
  color: white;
  transition: all .1s ease;
}
.boclAlertWarning #boclConfirmButton:hover {
  background: rgb(173, 135, 29);
}
/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
  .boclAlertWrapper {
    width: 19.5rem;
    top: 11%;
  }
}
/****************************************************************************/
.boclModalWrapper{
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.boclModalBox {
  background-color: #fefefe;
  margin: 20% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
  background: rgb(255, 255, 255);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 28px -15px rgb(0 0 0);
}
.boclModalButtonsDiv {
  display: flex;
  margin: 1rem;
  justify-content: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.boclModalButtonsDiv button{
  border-radius: 7px;
  margin: .4rem .8rem;
}
/* The Close Button */
.close {
  color: rgb(133, 133, 133);
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -1.1rem;
}
.close:hover,
.close:focus {
  color: rgb(14, 14, 14);
  text-decoration: none;
  cursor: pointer;
}
/**CELULAR*********************************************************/
@media screen and (max-width: 420px) {
  .boclModalButtonsDiv {
    display: flex;
  }
  .boclModalButtonsDiv a, .boclModalButtonsDiv button{
    margin: .4rem;
  }
  .boclModalButtonsDiv button{
    width: 85%;
  }
  .boclModalBox {
    margin: 30% auto;
    width: 90%;
  }
  
}
/*********************************************************************/
img.loopLoader_blue {
  filter: brightness(10) saturate(0) contrast(10);
}
.boclLoader{
  width: 2rem;
}
.btn-blue .boclLoader {
  margin-top: -0.4rem;
}
/*********************************************************************/
/**IMG ANIMATIONS**/
/**LIST:
LOOPS:
  slowbeat
  beat
  breath
  jelly
  jingle
  measure
  shiver
  spin
  horizontalflow
  slowhorizontalflow
TRANSITION IN:

/*********************************************************************/
.animation_slowbeat{
  animation: 1.11111s linear -0.641026s infinite normal forwards running slow_beat;
}
@keyframes slow_beat{ 
  0% {
    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);
    transform: scale(1);
  }
  34% {
      animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);
      transform: scale(1.06);
  }
  68% {
      animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);
      transform: scale(1.0268);
  }
  84% {
      animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);
      transform: scale(1.04);
  }
  100% {
      transform: scale(1);
  }
}
.animation_horizontalflow{
  animation: 1.11111s linear -0.641026s infinite normal forwards running horizontal_flow;
}
@keyframes horizontal_flow{ 
  0% {
    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);
    transform: translate(0,0);
  }
  33% {
      animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);
      transform: translate(-10px,0);
  }
  66% {
      animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);
      transform: translate(0,0);
  }
  84% {
      animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);
      transform: translate(10px,0);
  }
  100% {
    transform: translate(0,0);
  }
}
.animation_slowhorizontalflow{
  animation: 3s linear -0.641026s infinite normal forwards running slow_horizontal_flow;
}
@keyframes slow_horizontal_flow{ 
  0% {
    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);
    transform: translate(0,0);
  }
  33% {
      animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);
      transform: translate(-6px,0);
  }
  66% {
      animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);
      transform: translate(0,0);
  }
  84% {
      animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);
      transform: translate(6px,0);
  }
  100% {
    transform: translate(0,0);
  }
}
.animation_verticalsqueesh{
  animation: .4s linear -0.641026s infinite normal forwards running vertical_squeesh;
}
@keyframes vertical_squeesh{ 
  0% {
    transform: translate(0,0);
    height: 100%;
  }
  33% {

      transform: translate(0,-1px);
      height: 97%;
  }
  66% {

      transform: translate(0,0);
      height: 100%;
  }
  84% {
      transform: translate(0,1px);
      height: 101%;
  }
  100% {
    transform: translate(0,0);
    height: 100%;
  }
}
.animation_shorttempo{
  animation: 1.5s linear .5s infinite normal forwards running short_tempo;
}
@keyframes short_tempo{ 
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  50% {
    transform: rotate(4deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}
.animation_beat{
  animation: 1.11111s linear -0.641026s infinite normal forwards running beat;
}
@keyframes beat{ 
  0% {
    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);
    transform: scale(1);
  }
  34% {
      animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);
      transform: scale(1.2);
  }
  68% {
      animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);
      transform: scale(1.0268);
  }
  84% {
      animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);
      transform: scale(1.0932);
  }
  100% {
      transform: scale(1);
  }
}
.animation_slowbreath{
  animation: 2s linear -2s infinite normal forwards running slow_breath;
}
@keyframes slow_breath{
  0% {
    transform: scale(0.95);
  }
  51% {
      transform: scale(1);
  }
  100% {
      transform: scale(0.95);
  }
}
.animation_breath{
  animation: 1.11111s linear -0.598291s infinite normal forwards running breath;
}
@keyframes breath{
  0% {
    animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
    transform: scale(0.9099999999999999);
  }
  51% {
      animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
      transform: scale(1.02994);
  }
  100% {
      transform: scale(0.9099999999999999);
  }
}
.animation_jelly {
  animation: jelly 1s infinite linear;
}
@keyframes jelly{
  0% {
    animation-timing-function: cubic-bezier(0.1441,0.1912,0.6583,1.1029);
    transform: translate(0,0) skewX(0);
  }
  31% {
      animation-timing-function: cubic-bezier(0.0667,0.1419,0.6667,1.1415);
      transform: translate(-7.800000000000001px,0) skewX(7.800000000000001deg);
  }
  45% {
      animation-timing-function: cubic-bezier(0.0542,0.1151,0.5697,1.181);
      transform: translate(4.680000000000001px,0) skewX(-4.680000000000001deg);
  }
  59% {
      animation-timing-function: cubic-bezier(0.0497,0.1058,0.4541,1.231);
      transform: translate(-2.8100000000000005px,0) skewX(2.8100000000000005deg);
  }
  73% {
      animation-timing-function: cubic-bezier(0.0808,0.1711,0.4109,1.2519);
      transform: translate(1.6800000000000002px,0) skewX(-1.6800000000000002deg);
  }
  87% {
      animation-timing-function: cubic-bezier(0.2073,0.3705,0.4064,0.8839);
      transform: translate(-1.01px,0) skewX(1.01deg);
  }
  100% {
      transform: translate(0.78px,0) skewX(-0.78deg);
  }
}
.animation_jingle {
  animation: jingle 1s infinite linear;
  transform-origin: 50% 0;
}
@keyframes jingle {
  0% {
    animation-timing-function: cubic-bezier(0.146,0.2111,0.5902,1.3204);
    transform: rotate(0);
  }
  11% {
      animation-timing-function: cubic-bezier(0.1079,0.1992,-0.6462,0.828);
      transform: rotate(
  7.61deg);
  }
  23% {
      animation-timing-function: cubic-bezier(0.0504,0.0951,0.0163,0.9677);
      transform: rotate(
  -5.789999999999999deg);
  }
  36% {
      animation-timing-function: cubic-bezier(0.0475,0.0921,0.3134,1.0455);
      transform: rotate(
  3.35deg);
  }
  49% {
      animation-timing-function: cubic-bezier(0.0789,0.1565,0.3413,1.0972);
      transform: rotate(
  -1.9300000000000002deg);
  }
  62% {
      animation-timing-function: cubic-bezier(0.141,0.2885,0.406,1.1519);
      transform: rotate(
  1.12deg);
  }
  75% {
      animation-timing-function: cubic-bezier(0.226,0.4698,0.5031,1.1722);
      transform: rotate(
  -0.64deg);
  }
  88% {
      animation-timing-function: cubic-bezier(0.3121,0.5521,0.5655,0.8997);
      transform: rotate(
  0.37deg);
  }
  100% {
      transform: rotate(
  -0.28deg);
  }
}
.animation_measure {
  animation: measure 5s infinite linear;
}
@keyframes measure {
  0% {
    transform: translate(0,0) rotate(0);
  } 
  3.33333% {
      transform: translate(0,0) rotate(
  -14.99241deg);
  }
  6.66667% {
      transform: translate(0,0) rotate(
  10.93733deg);
  }
  10% {
      transform: translate(0,0) rotate(
  10.60793deg);
  }
  13.3333% {
      transform: translate(0,0) rotate(
  -10.26249deg);
  }
  16.6667% {
      transform: translate(0,0) rotate(
  0.47337deg);
  }
  20% {
      transform: translate(0,0) rotate(
  15deg);
  }
  23.3333% {
      transform: translate(0,0) rotate(
  0.48087deg);
  }
  26.6667% {
      transform: translate(0,0) rotate(
  -10.26796deg);
  }
  30% {
      transform: translate(0,0) rotate(
  10.60262deg);
  }
  33.3333% {
      transform: translate(0,0) rotate(
  10.94246deg);
  }
  36.6667% {
      transform: translate(0,0) rotate(
  -14.99265deg);
  }
  40% {
      transform: translate(0,0) rotate(
  -0.0075deg);
  }
  43.3333% {
      transform: translate(0,0) rotate(
  14.99217deg);
  }
  46.6667% {
      transform: translate(0,0) rotate(
  -10.93219deg);
  }
  50% {
      transform: translate(0,0) rotate(
  -10.61323deg);
  }
  53.3333% {
      transform: translate(0,0) rotate(
  10.25702deg);
  }
  56.6667% {
      transform: translate(0,0) rotate(
  -0.46588deg);
  }
  60% {
      transform: translate(0,0) rotate(
  -15deg);
  }
  63.3333% {
      transform: translate(0,0) rotate(
  -0.48836deg);
  }
  66.6667% {
      transform: translate(0,0) rotate(
  10.27343deg);
  }
  70% {
      transform: translate(0,0) rotate(
  -10.59732deg);
  }
  73.3333% {
      transform: translate(0,0) rotate(
  -10.94759deg);
  }
  76.6667% {
      transform: translate(0,0) rotate(
  14.99288deg);
  }
  80% {
      transform: translate(0,0) rotate(
  0.015deg);
  }
  83.3333% {
      transform: translate(0,0) rotate(
  -14.99193deg);
  }
  86.6667% {
      transform: translate(0,0) rotate(
  10.92706deg);
  }
  90% {
      transform: translate(0,0) rotate(
  10.61853deg);
  }
  93.3333% {
      transform: translate(0,0) rotate(
  -10.25155deg);
  }
  96.6667% {
      transform: translate(0,0) rotate(
  0.45838deg);
  }
  100% {
      transform: translate(0,0) rotate(0);
  }
}
.animation_shiver {
  animation: shiver .5s infinite linear;
}
@keyframes shiver {
  0% {
    transform: translate(0,0) scale(1);
  }
  3.33333% {
      transform: translate(0,0) scale(1.04279);
  }
  6.66667% {
      transform: translate(0,0) scale(1.00524);
  }
  10% {
      transform: translate(0,0) scale(0.96369);
  }
  13.3333% {
      transform: translate(0,0) scale(0.95064);
  }
  16.6667% {
      transform: translate(0,0) scale(0.97651);
  }
  20% {
      transform: translate(0,0) scale(1.02078);
  }
  23.3333% {
      transform: translate(0,0) scale(1.04875);
  }
  26.6667% {
      transform: translate(0,0) scale(1.03832);
  }
  30% {
      transform: translate(0,0) scale(0.99777);
  }
  33.3333% {
      transform: translate(0,0) scale(0.95897);
  }
  36.6667% {
      transform: translate(0,0) scale(0.95244);
  }
  40% {
      transform: translate(0,0) scale(0.98335);
  }
  43.3333% {
      transform: translate(0,0) scale(1.02733);
  }
  46.6667% {
      transform: translate(0,0) scale(1.04988);
  }
  50% {
      transform: translate(0,0) scale(1.0331);
  }
  53.3333% {
      transform: translate(0,0) scale(0.99035);
  }
  56.6667% {
      transform: translate(0,0) scale(0.95507);
  }
  60% {
      transform: translate(0,0) scale(0.95527);
  }
  63.3333% {
      transform: translate(0,0) scale(0.99057);
  }
  66.6667% {
      transform: translate(0,0) scale(1.03343);
  }
  70% {
      transform: translate(0,0) scale(1.04984);
  }
  73.3333% {
      transform: translate(0,0) scale(1.02714);
  }
  76.6667% {
      transform: translate(0,0) scale(0.98293);
  }
  80% {
      transform: translate(0,0) scale(0.9523);
  }
  83.3333% {
      transform: translate(0,0) scale(0.9591);
  }
  86.6667% {
      transform: translate(0,0) scale(0.99821);
  }
  90% {
      transform: translate(0,0) scale(1.0386);
  }
  93.3333% {
      transform: translate(0,0) scale(1.0487);
  }
  96.6667% {
      transform: translate(0,0) scale(1.02038);
  }
  100% {
      transform: translate(0,0) scale(1);
  }
}
.animation_spin {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotate(0);
  }
  100% {
      transform: rotate(
  360deg);
  }
}
.transitionIn_bounceRightLeft {
  /* animation-play-state: paused; */
  animation: bounceRightLeft 1s 1 linear;
  animation-fill-mode: forwards;
  transform: matrix(1,0,0,1,49.75,0);
  opacity: 0;
}
@keyframes bounceRightLeft {
  0% {
    animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
    transform: translate(49.75px,0);
    opacity: 0;
}
31% {
    animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
    transform: translate(2.65px,0);
    opacity: 1;
}
51% {
    animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
    transform: translate(20px,0);
    opacity: 1;
}
72% {
    animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
    transform: translate(1.05px,0);
    opacity: 1;
}
87% {
    animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
    transform: translate(7.9px,0);
    opacity: 1;
}
100% {
    transform: translate(0,0);
    opacity: 1;
}
}
.transitionIn_floatRightLeft {
  animation: floatRightLeft 1s 1 linear;
  animation-fill-mode: forwards;
  transform: matrix(1,0,0,1,14.925,0);
  opacity: .05;
}
@keyframes floatRightLeft {
  0% {
    animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
    transform: translate(14.925px,0);
    opacity: .05;
}
100% {
    transform: translate(0,0);
    opacity: 1;
}
}