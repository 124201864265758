
.album-item {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 100%);
    word-break: keep-all;
    box-shadow: 0px 4px 20px -8px rgb(26 26 26);
    position: relative;
    width: 30%;
    user-drag: auto;
    -webkit-user-drag: auto;
    height: 10rem;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    margin: 0 1.5rem 0 0;
    transition: all 0.2s ease-out;
    /* border-top: 4px solid orange; */
    transform: scale(1);
    background-size: cover;
    background-position: right;
    padding-right: 0.2rem;
  }
  .album-item.sh-white > .album-details, .album-item.sh-yellow > .album-details{
    color: black;;
  }
  .album-item-only-one{
    width: 97%;
  }
  .album-details {
    background: transparent;
    width: 100%;
    text-shadow: 0 0px 1px rgb(0 0 0 / 40%);
    padding: 0.6rem 1rem 0.6rem 1rem;
    white-space: initial;
    float: left;
    box-sizing: border-box;
    color: white;
    height: 100%;
    font-size: 2.5rem;
    font-weight: 600;
    z-index: 99;
    overflow: overlay;
  }
  .av-black>.album-details{
    color: black;
  }
  .album-details .title {
    font-size: .9rem;
    display: block;
    text-transform: uppercase;
    text-align: left;
  }
  .album-details .subtext {
    font-size: .85rem;
    display: block;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    margin-top: .3rem;
  }
  .subtext a {
    color: black;
    text-decoration: none;
    background-color: transparent;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .album-details::-webkit-scrollbar {
    width: .3rem;
    height: .25rem;
    margin: .5rem;
    /* display: none; */
  }
  .album-details::-webkit-scrollbar-track {
    background: transparent;
    /* display: none; */
    margin: 1rem;
  }
  .album-details::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    height: .25rem;
    border-radius: 10px;
    /* display: none; */
  }
  @media only screen 
    and (min-device-width: 315px) 
    and (max-device-width: 350px) 
    and (-webkit-min-device-pixel-ratio: 2){
    #div_avisos .arrow-right, #div_avisos .arrow-left{
      top: calc(50% - 1.5rem);
      line-height: 2.2;
    }
  }
  /**BACKGROUND AVISOS**/
  .av-orange{
    border-top: 6px solid rgb(214, 110, 25);
  }
  .av-blue{
    border-top: 6px solid rgb(36, 93, 216);
  }
  .av-sky{
    border-top: 6px solid rgb(36, 180, 216);
  }
  .av-purple{
    border-top: 6px solid rgb(179, 56, 179);
  }
  .av-black{
    border-top: 6px solid rgb(77, 77, 77);
  }
  .av-green{
    border-top: 6px solid rgb(34, 138, 34);
  }
  .av-red{
    border-top: 6px solid rgb(190, 45, 45);
  }
  .av-yellow{
    border-top: 6px solid rgb(212, 183, 17);
  }
  .album-item .album-details .title{
    color: rgb(31, 31, 31);
    font-family: 'Montserrat', sans-serif;
  }
  .album-item .album-details .subtext{
    color: rgb(53, 53, 53);
  }

/*TABLET ****/
@media screen and (max-width: 890px) {
    .album-item {
        width: 48%;
    }
}
/*CELULAR ****/
@media screen and (max-width: 490px) {
    .album-item {
        width: 97%;
    }
}